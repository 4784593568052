import { IntegrationActivity } from '@app/app/interfaces/integration.model';
import {IntegrationCredentials} from "@app/app/interfaces/integrations/integration-credentials.model";

export class GetIntegrations {
  static readonly type = '[Integrations] GetIntegrations';
  constructor() {}
}

export class GetIntegrationDetail {
  static readonly type = '[IntegrationActivities] GetIntegrationDetail';

  /**
   *
   * @param customerIntegrationId Number - DB_saasConfigurations.integrationActivities.customerIntegrationId
   */
  constructor(
    public customerIntegrationId: number,
  ) {}
}

export class GetIntegrationActivities {
  static readonly type = '[IntegrationActivities] GetIntegrationActivities';
  /**
   *
   * @param customerIntegrationId Number - DB_saasConfigurations.integrationActivities.customerIntegrationId
   * @param currentPage Number - page
   * @param pageSize Number - page size limit
   * @param shouldReloadOnFetch Boolean
   */
  constructor(
    public customerIntegrationId: number,
    public currentPage: number,
    public pageSize: number,
    public shouldReloadOnFetch: boolean,
  ) {}
}

export class CreateIntegrationCredentials {
  static readonly type = '[Integrations] CreateIntegrationCredentials';
  constructor(
    public customerIntegrationId: number,
    public credentials: IntegrationCredentials,
  ) {}
}

export class UpdateIntegrationCredentials {
  static readonly type = '[Integrations] UpdateIntegrationCredentials';
  constructor(
    public customerIntegrationId: number,
    public credentials: IntegrationCredentials,
  ) {}
}

export class GetIntegrationCredentials {
  static readonly type = '[Integrations] GetIntegrationCredentials';
  constructor(
    public customerIntegrationId: number,
  ) {}
}

export class AddIntegrationActivity {
  static readonly type = '[Integrations] AddIntegrationActivity';
  constructor(
    public integrationActivity: IntegrationActivity,
  ) {}
}

export class IntegrationActivityPusherSubscribe {
  static readonly type = '[Integrations] IntegrationActivityPusherSubscribe';
  constructor(
    public lenderId: number,
  ) {}
}

export class IntegrationActivityPusherUnsubscribe {
  static readonly type = '[Integrations] IntegrationActivityPusherUnsubscribe';
  constructor(
    public lenderId: number,
  ) {}
}
