import {IntegrationActivity} from "@app/app/interfaces/integration.model";

export class PushToLaserPro {
  static readonly type = '[LaserPro] PushToLaserPro';
  constructor(
    public dealId: number,
    public businessName: string,
  ) {}
}

export class SubscribeToLaserPro {
  static readonly type = '[LaserPro] SubscribeToLaserPro';
  constructor(
    public dealId: number,
  ) {}
}

export class UnsubscribeFromLaserPro {
  static readonly type = '[LaserPro] UnsubscribeFromLaserPro';
  constructor(
    public dealId: number,
  ) {}
}

export class AddNewLaserProProgress {
  static readonly type = '[LaserPro] AddNewLaserProUpdate';
  constructor(
    public integrationActivity: IntegrationActivity,
  ) {}
}
