import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { LendioResponse } from '../interfaces/lendio-response';
import { LenderTaskEnrollmentParams } from '../store/lender-tasks/lender-tasks.actions';
import { LenderActionItemUserEnrollment } from '../interfaces/lender-action-item-user-enrollment.model';
import { LenderTask } from '../interfaces/lender-task.model';

@Injectable({ providedIn: 'root' })
export class LenderTasksService {
  constructor(private _http: HttpClient) {}

  public getTasks() {
    return this._http.get<LendioResponse<LenderTask[]>>(
      `${environment.apiUrl}/partner-portal/lender-action-items`
    );
  }

  public createOrUpdateTaskEnrollment(params: LenderTaskEnrollmentParams) {
    return this._http.post<LendioResponse<LenderActionItemUserEnrollment>>(
      `${environment.apiUrl}/partner-portal/lender-action-items/${params.lenderActionItemId}/users/${params.userId}/enroll`,
      {
        assigned: params.assigned,
        read: params.read,
      }
    );
  }
}
