<div class="lendio-snack-bar tall-snack" #lendioSnackBar>
    <div class="lendio-snack-bar-prefix"
            [ngStyle]="prefixBackgroundColor
        ? {'background-color': prefixBackgroundColor }
        : null">
            <mat-icon>{{prefixIcon}}</mat-icon>
    </div>
    <div class="lendio-snack-bar-text">
        <div class="lendio-snack-bar-title" *ngIf="title">
            {{ title }}
        </div>
        <div class="lendio-snack-bar-message">
            {{ message ? message : '' }}
        </div>
    </div>
    <div class="lendio-snack-bar-actions">
        <button class="lendio-snack-bar-action-button"
            *ngIf="showRefreshBusinesses"
            (click)="refreshBusinesses()">
            <span class="action-button-label">Refresh</span>
        </button>
        <button class="lendio-snack-bar-action-button"
                *ngIf="showViewReports"
                (click)="viewReports()">
            <span class="action-button-label">View</span>
        </button>
        <button class="lendio-snack-bar-dismiss-button"
                data-cy="lendio-snack-bar-dismiss-button"
                *ngIf="canDismiss"
                (click)="snackBarDismiss()">
            <img src="assets/images/snack-bar-close.png" alt="">
        </button>
    </div>
</div>
