import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeData from '@angular/common/locales/en';
import { dateFilterComparator } from '@app/app/components/grid-components/grid-comparitors';
import { ActivityCellComponent } from './cell-components/activity-cell/activity-cell.component';
import { DescriptionCellComponent } from './cell-components/description-cell/description-cell.component';
import { DateCellComponent } from './cell-components/date-cell/date-cell.component';
import { dealActivities, offerActivities, documentActivities, noteActivities, otherActivities } from './deal-activities-constants';
import { DealActivity } from '@app/app/interfaces/deal-activity.model';
import * as moment from 'moment-timezone';

registerLocaleData(localeData);
const datePipe = new DatePipe('en-US');

export const dealActivityFormatter = ({value, data}: ValueFormatterParams | { value: string, data?: any }): string => {
  if (!value) {
    return '';
  }

  const dealActivityTypeMap = {
    ...dealActivities,
    ...offerActivities,
    ...documentActivities,
    ...noteActivities,
    ...otherActivities,
  };

  return dealActivityTypeMap[value] ?? value;
}

export const COL_DEF_ACTIVITY_TYPE: ColDef = {
  minWidth: 400,
  hide: false,
  headerName: 'Activity',
  field: 'type',
  colId: 'activityType',
  filter: 'agSetColumnFilter',
  cellRenderer: ActivityCellComponent,
  valueFormatter: dealActivityFormatter,
  filterParams: {
    valueFormatter: dealActivityFormatter
  },
  getQuickFilterText: params => {
    return dealActivityFormatter(params);
  },
  comparator: (valueA, valueB) => {
    const nameA: string = dealActivityFormatter({ value: valueA?.trim() });
    const nameB: string = dealActivityFormatter({ value: valueB?.trim() });

    return nameA?.localeCompare(nameB);
  },
  sortable: false,
};

export const COL_DEF_DESCRIPTION: ColDef = {
  minWidth: 400,
  flex: 1,
  hide: false,
  headerName: 'Description',
  field: 'actor',
  colId: 'description',
  cellRenderer: DescriptionCellComponent,
  filter: 'agSetColumnFilter',
  valueFormatter: ({ value, data }: { value: string, data: DealActivity }) => {
    const { description, action, actor } = data;
    return `${description} ${action} ${actor}`;
  },
  getQuickFilterText: ({ value, data }: { value: string, data: DealActivity }) => {
    const { description, action, actor } = data;
    return `${description} ${action} ${actor}`;
  },
  comparator: (valueA, valueB) => {
    const typeA: string = valueA?.trim();
    const typeB: string = valueB?.trim();
    return typeA?.localeCompare(typeB);
  },
  sortable: false,
};

export const COL_DEF_CREATED: ColDef = {
  minWidth: 234,
  hide: false,
  headerName: 'Date',
  field: 'created',
  colId: 'created',
  filter: 'agDateColumnFilter',
  filterParams: {
    maxValidDate: moment().format('YYYY-MM-DD'),
    comparator: dateFilterComparator,
    filterOptions: [
      'lessThan',
      'greaterThan',
      'inRange'
    ],
    defaultOption: 'lessThan',
    inRangeInclusive: true,
  },
  getQuickFilterText: ({ value, data }: { value: number, data: DealActivity }) => {
    const dateString = datePipe.transform(value * 1000, 'short')!;
    const timeAgo =  moment.unix(value).fromNow();

    return `${timeAgo} ${dateString}`;
  },
  cellRenderer: DateCellComponent,
  valueFormatter: ({ value }: ValueFormatterParams) => {
    return value
      ? datePipe.transform((value * 1000), 'short')!
      : '';
  },
  comparator: (valueA, valueB) => {
    return valueA - valueB;
  },

};
