<mat-card appearance="outlined" class="m-4 new-theme-typography lendio-density-1 inline">
    <mat-card-header>
        <mat-card-title>Demo files generator</mat-card-title>
        <mat-card-subtitle>
            Generate CSV files for businesses, contacts, bank accounts, and transactions.
        </mat-card-subtitle>
    </mat-card-header>
    <div class="lendio-mat-card-divider"></div>
    <div [appGrowAnimation]="advancedOptionsVisible">
        @if (advancedOptionsVisible) {
            <mat-card-content>
                <form [formGroup]="demoFilesForm">
                    <div class="mat-body-medium !mb-4">Accounts per business</div>
                    <div class="flex flex-row gap-4 mb-6">
                        <mat-form-field appearance="outline" class="w-48">
                            <mat-label>Min</mat-label>
                            <input matInput type="number"
                                   formControlName="accountMin"
                                   [min]="1"
                                   [max]="5"
                                   (blur)="validateAccounts()">
                            <mat-hint>Between 1 - 5</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-48">
                            <mat-label>Max</mat-label>
                            <input matInput
                                   type="number"
                                   formControlName="accountMax"
                                   [min]="1"
                                   [max]="5"
                                   (blur)="validateAccounts()">
                            <mat-hint>Between 1 - 5</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="mat-body-medium !mb-4">Transactions per account</div>
                    <div class="flex flex-row gap-4 mb-6">
                        <mat-form-field appearance="outline" class="w-48">
                            <mat-label>Min</mat-label>
                            <input matInput type="number"
                                   formControlName="transactionMin"
                                   [min]="1"
                                   [max]="300"
                                   (blur)="validateTransactions()">
                            <mat-hint>Between 1 - 300</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-48">
                            <mat-label>Max</mat-label>
                            <input matInput
                                   type="number"
                                   formControlName="transactionMax"
                                   [min]="1"
                                   [max]="300"
                                   (blur)="validateTransactions()">
                            <mat-hint>Between 1 - 300</mat-hint>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
        }
    </div>
    <mat-card-content>
        <div class="flex flex-row justify-between">
            <button mat-button (click)="toggleAdvancedOptions()" class="btn-advanced-options">
                <span class="btn-text-advanced-options">Advanced options</span>
                <mat-icon iconPositionEnd
                          class="icon-advanced-options"
                          [class.expanded]="advancedOptionsVisible">
                    expand_more
                </mat-icon>
            </button>
            <button mat-flat-button
                    color="accent"
                    [disabled]="downloadIsDisabled"
                    (click)="generateAll()">
                Generate Demo Files
            </button>
        </div>
    </mat-card-content>
</mat-card>
