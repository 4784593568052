import {
  ColDef,
  GridApi,
  GridOptions,
} from 'ag-grid-community';

import {
  dealActivitiesColumnLimits,
  dealActivitiesDefaultMinWidth,
  GRID_BASE_CONFIG,
  GRID_BASE_DEFAULT_COL_DEF,
} from './deal-activities-grid/deal-activities-constants';


export const sizeColsToFit = (api: GridApi) => {
  api.sizeColumnsToFit({
    defaultMinWidth: dealActivitiesDefaultMinWidth,
    columnLimits: dealActivitiesColumnLimits
  });
};


export const dealActivitiesGridConfig: GridOptions = {
  ...GRID_BASE_CONFIG,
}

export const dealActivitiesGridDefaultColDef: ColDef = {
  ...GRID_BASE_DEFAULT_COL_DEF,
}

