import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {LendioResponse} from "@app/app/interfaces/lendio-response";
import {environment} from "@app/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class LaserProService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  /**
   * @param dealId
   * @return LendioResponse
   */
  pushToLaserPro(dealId: number): Observable<LendioResponse> {
    return this.httpClient.post<LendioResponse>(`${environment.apiUrl}/partner-portal/deals/${dealId}/push-to-laser-pro`,
      {
      }
    )
  }
}
