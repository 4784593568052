@if (loadingService.isLoading | async) {
    <app-loading [@fadeInOut]></app-loading>
} @else {
    <span [@fadeInOut]>
        <app-snackbar-manager></app-snackbar-manager>

        <ng-template #FeaturesLoading>
            <app-dot-loader-scale class="app-root-dot-loader"></app-dot-loader-scale>
        </ng-template>

        @if (siteIsUpdated) {
            <app-alert class="site-updated-alert" level="info"
                       message="Updates available! Please hard refresh at your earliest convenience. Windows: Shift+Ctrl+R. Mac: Shift+Command+R.">
            </app-alert>
        }

        @if (unsupportedBrowser) {
            <div class="unsupported-browser">
                Your current browser is not supported. To improve your experience using Lendio's Partner Portal, please upgrade to a
                supported browser such as
                <a class="text-blue-500 hover:text-blue-800" href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>, or 
                <a class="text-blue-500 hover:text-blue-800" href="https://www.microsoft.com/en-us/edge" target="_blank">Microsoft
                    Edge</a>.
            </div>
        }
        @if (!newThemeEnabled()) {
            <ng-container *ngIf="(currentUser$ | async) && !routeContainsAuth; else OldThemeNoAuth">
                <ng-container *ngIf="(sassFeaturesLoaded$ | async); else FeaturesLoadingOldTheme">
                    <div class="h-full w-full flex justify-center old-theme-app-styles" [ngClass]="(unsupportedBrowser ? 'pt-16' : '')">
                        <div class="mat-app-background basic-container mat-typography relative h-full w-full">
                            <mat-toolbar role="heading" class="!px-2 main-nav">
                                <span [ngClass]="(notLendio ? 'logo with-partner' : 'logo')"></span>
                                <span *ngIf="notLendio" class="partner-divider"> | </span>
                                <span *ngIf="notLendio" class="partner mr-3" data-cy="partner-name">{{ institutionName }}</span>
                                <app-hijack-smart *ngIf="(currentUser$ | async)?.permissions?.canHijack"></app-hijack-smart>

                                <!-- This fills the remaining space of the current row -->
                                <span class="fill-remaining"></span>

                                <app-applications-search *ngIf="(currentUser$ | async)?.isLender() && (currentUser$ | async)?.institution?.id !== 44566"
                                                         class="invisible lg:!visible"></app-applications-search>

                                <span class="app-user" [matMenuTriggerFor]="userMenu" data-cy="user-drop-down">
                                    <span class="username">{{ (currentUser$ | async)?.fullName }}</span>
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </span>

                                <mat-menu #userMenu="matMenu">
                                    <button mat-menu-item (click)="logout()" data-cy="logout-btn" class="px-2">
                                        <mat-icon>power_settings_new</mat-icon>
                                        <span class="mr-1">Log Out</span>
                                    </button>
                                </mat-menu>
                                <app-notification-icon-smart *ngIf="(currentUser$ | async)?.isLender()"></app-notification-icon-smart>
                            </mat-toolbar>

                            <mat-sidenav-container autosize fullscreen hasBackdrop="false" class="old-sidenav-container">
                                <mat-sidenav class="main-sidenav" mode="side" opened="true" disableClose>
                                    <div class="mt-5">
                                        <app-nav-button *ngIf="(currentUser$ | async)?.isLender() && !(currentUser$ | async)?.institution?.isPPPLender"
                                                        icon="dashboard" label="Dashboard" link="/dashboard"></app-nav-button>
                                        <app-nav-button *ngIf="(currentUser$ | async)?.isLender()" data-cy="nav-apps" icon="file_copy" label="Applications"
                                                        link="/applications"></app-nav-button>
                                        <app-nav-button *ngIf="(currentUser$ | async)?.isLender()" data-cy="nav-renewals" icon="replay" label="Renewals"
                                                        link="/renewals"></app-nav-button>
                                        <app-nav-button *ngIf="(currentUser$ | async)?.isLender() && (hasReportsListFeature$ | async)"
                                                        data-cy="nav-reports" icon="filter_alt" label="Reports"
                                                        link="/reports"></app-nav-button>
                                        <app-nav-button *ngIf="((currentUser$ | async)?.isReferralPartner()) && !(hasPartnerDashboardFeature$ | async) && !(hasAffiliateDashboardFeature$ | async)" data-cy="nav-clients" icon="file_copy"
                                                        label="Clients" link="/clients"></app-nav-button>
                                        <app-nav-button *ngIf="((currentUser$ | async)?.isReferralPartner()) && ((hasAffiliateDashboardFeature$ | async) || (hasPartnerDashboardFeature$ | async))" data-cy="nav-partner-dashboard" icon="dashboard"
                                                        label="Dashboard" link="/partner-dashboard"></app-nav-button>
                                        <app-nav-button data-cy="nav-forgive"
                                                        *ngIf="(currentUser$ | async)?.permissions?.canUploadLoanForgivenessCSV || (currentUser$ | async)?.permittedTo('canManageLoanForgivenessCSV')"
                                                        icon="cloud_upload" label="Forgiveness" link="/csv/forgive"></app-nav-button>
                                        <app-nav-button data-cy="nav-api" *ngIf="(currentUser$ | async)?.permittedTo('lpxManageAPI')" icon="code" label="API"
                                                        link="administration/api"></app-nav-button>
                                        <app-nav-button *ngIf="canSeeEmbeddedConfig" data-cy="nav-embedded" icon="settings_overscan"
                                                        label="Embedded App" link="/embedded-app"></app-nav-button>
                                        <app-nav-button data-cy="nav-settings" icon="settings" label="Settings"
                                                        link="/settings"></app-nav-button>
                                    </div>
                                </mat-sidenav>
                                <mat-sidenav-content>
                                    <mat-drawer-container class="h-full" autosize>
                                        <!-- Content Outside of Drawer -->
                                        <div class="p-3">

                                            <!-- Global Alerts Banners -->
                                            <div *ngIf="alerts">
                                                <app-alert
                                                    *ngFor="let alert of alerts"
                                                    [level]="alert.level"
                                                    [message]="alert.message"
                                                    [dismissible]="true"
                                                    (dismissed)="dismissAlert(alert)"
                                                ></app-alert>
                                            </div>

                                            <!-- Main Router Outlet -->
                                            <router-outlet *ngIf="!isForbidden"></router-outlet>
                                            <app-not-found *ngIf="isForbidden"></app-not-found>
                                        </div>

                                        <!-- Notifications Drawer -->
                                        <mat-drawer class="mat-drawer--notifications" #notificationsidenav
                                                    opened="{{ notificationSideNavExpanded }}"
                                                    position="end" mode="side">
                                            <app-notification-sidenav-smart></app-notification-sidenav-smart>
                                        </mat-drawer>
                                    </mat-drawer-container>
                                </mat-sidenav-content>
                            </mat-sidenav-container>
                        </div>
                    </div>
                </ng-container>
                <ng-template #FeaturesLoadingOldTheme
                             [ngTemplateOutlet]="FeaturesLoading">
                </ng-template>
            </ng-container>
            <ng-template #OldThemeNoAuth>
                <div class="h-full w-full flex justify-center old-theme-app-styles" [ngClass]="(unsupportedBrowser ? 'pt-16' : '')">
                    <div class="basic-container mat-typography self-center">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </ng-template>
        } @else {
            @if ((currentUser$ | async) && !routeContainsAuth) {
                @if (sassFeaturesLoaded$ | async) {
                    <mat-sidenav-container>
                        <mat-sidenav #lendioSidenav
                                     id="lendioSidenav"
                                     data-cy="sidenav"
                                     mode="side"
                                     disableClose
                                     [opened]="navCollapsed">
                            <div class="lendio-logo-container">
                                <img class="lendio-logo"
                                     [src]="!lendioSidenav.opened
                                                ? 'assets/images/logomark - light.svg'
                                                : 'assets/images/logo - light.svg'"
                                     alt="lendio">
                                @if (lendioSidenav.opened) {
                                    <button mat-icon-button
                                            class="button-nav-size nav-collapse"
                                            (click)="lendioSidenav.close()">
                                        <mat-icon class="nav-collapse-icon"
                                                  fontSet="material-icons-outlined">
                                            <span>start</span>
                                        </mat-icon>
                                    </button>
                                }
                            </div>
                            <mat-nav-list>

                                <!-- Dashboard -->
                                @if ((currentUser$ | async)?.isLender() && !(currentUser$ | async)?.institution?.isPPPLender) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'dashboard'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Dashboard' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>dashboard</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Dashboard' | titlecase }}</span>
                                        }
                                    </a>
                                }

                                <!-- Partner Dashboard -->
                                @if (((currentUser$ | async)?.isReferralPartner()) && ((hasAffiliateDashboardFeature$ | async) || (hasPartnerDashboardFeature$ | async))) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       data-cy="nav-partner-dashboard"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'partner-dashboard'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Dashboard' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>dashboard</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Dashboard' | titlecase }}</span>
                                        }
                                    </a>
                                }

                                <!-- Tasks -->
                                @if (hasSmartTasksFeature$ | async) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'tasks'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Tasks' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>task_alt</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Tasks' | titlecase }}</span>
                                        }
                                    </a>
                                }

                                <!-- Businesses -->
                                @if ((currentUser$ | async)?.isLender() && (hasBusinessListFeature$ | async)) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'businesses'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Businesses' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>store</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Businesses' | titlecase }}</span>
                                        }
                                    </a>
                                }

                                <!-- Applications -->
                                @if ((currentUser$ | async)?.isLender() && !funnelFeatureEnabled) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       data-cy="nav-apps"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       id="funnelNavLink"
                                       [routerLink]="'applications'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Funnel' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>filter_alt</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <!-- span>{{ 'Funnel' | titlecase }}</span -->
                                            <!-- todo uncomment above and remove below when funnel goes live -->
                                            <span>
                                                @if (showFunnelNavItem) {
                                                    Funnel
                                                } @else {
                                                    Applications
                                                }
                                            </span>
                                        }
                                    </a>
                                }

                                <!-- Funnel -->
                                @if (
                                    ((currentUser$ | async)?.isLender() && funnelFeatureEnabled) ||
                                    (currentUser$ | async)?.isE2EUser()
                                ) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       data-cy="nav-apps"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       id="funnelV2NavLink"
                                       [routerLink]="'funnel'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Funnel V2' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>filter_alt</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>Funnel</span>
                                        }
                                    </a>
                                }

                                <!-- Renewals -->
                                @if ((currentUser$ | async)?.isLender() && !funnelFeatureEnabled ||
                                    (currentUser$ | async)?.isE2EUser()) {
                                    <a mat-list-item
                                       *ngIf="(currentUser$ | async)?.isLender()"
                                       data-cy="nav-renewals"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'/renewals'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Renewals' : ''"
                                       matTooltipPosition="after">
                                        <mat-icon>replay</mat-icon>
                                        <span *ngIf="lendioSidenav.opened">{{ 'Renewals' | titlecase }}</span>
                                    </a>
                                }

                                <!-- Reports -->
                                @if (hasReportsListFeature$ | async) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       data-cy="nav-reports"
                                       routerLinkActive
                                       id="reportsNavItem"
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'/reports'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Reports' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>insert_chart</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Reports' | titlecase }}</span>
                                        }
                                    </a>
                                }

                                <!-- Marketing -->
                                @if ((currentUser$ | async)?.isLender() && (currentUser$ | async)?.permittedTo('lpxCanSeeMarketingModule')) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       data-cy="nav-marketing"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'/marketing'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Marketing' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>chat_filled</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'marketing' | titlecase }}</span>
                                        }
                                    </a>
                                }

                                <!-- Clients -->
                                @if (((currentUser$ | async)?.isReferralPartner()) && !(hasPartnerDashboardFeature$ | async) && !(hasAffiliateDashboardFeature$ | async)) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       data-cy="nav-clients"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'/clients'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Clients' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>file_copy</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Clients' | titlecase }}</span>
                                        }
                                    </a>
                                }

                                <!-- Forgiveness -->
                                @if ((currentUser$ | async)?.permissions?.canUploadLoanForgivenessCSV || (currentUser$ | async)?.permittedTo('canManageLoanForgivenessCSV')) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       data-cy="nav-forgive"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'/csv/forgive'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Forgiveness' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="activeComponent.isActive
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>cloud_upload</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Forgiveness' | titlecase }}</span>
                                        }
                                    </a>
                                }

                                <!-- Administration -->
                                @if (adminVisible$ | async) {
                                    <a mat-list-item
                                       class="lendio-nav-link"
                                       data-cy="nav-administration"
                                       routerLinkActive
                                       #activeComponent="routerLinkActive"
                                       [routerLink]="'/administration'"
                                       [class.is-active]="activeComponent.isActive"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                       [matTooltip]="!lendioSidenav.opened ? 'Administration' : ''"
                                       matTooltipPosition="after"
                                       (click)="forceLinkActive(activeComponent)">
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>admin_panel_settings</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Administration' | titlecase }}</span>
                                        }
                                    </a>
                                }
                            </mat-nav-list>
                            <mat-nav-list class="nav-bottom">

                                <!-- Support -->
                                @if ((currentUser$ | async)?.permittedTo('viewProductSupportChat')) {
                                    <a mat-list-item
                                       class="support"
                                       (click)="openZenDesk()"
                                       [class.collapsed]="!lendioSidenav.opened"
                                       [class.nav-bottom-extended]="lendioSidenav.opened"
                                       [class.nav-bottom-collapsed]="!lendioSidenav.opened && !isSmallOrExtraSmall"
                                       [matTooltip]="!lendioSidenav.opened ? 'Help & Support' : ''"
                                       matTooltipPosition="after">
                                        <mat-icon fontSet="material-icons-outlined">help_outline</mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Support & Help' | titlecase }}</span>
                                        }
                                    </a>
                                }
                            </mat-nav-list>

                            <!-- Expand button -->
                            @if (!isSmallOrExtraSmall && !lendioSidenav.opened) {
                                <button mat-icon-button
                                        class="button-nav-size nav-expand"
                                        (click)="lendioSidenav.open()">
                                    <mat-icon class="nav-expand-icon"
                                              fontSet="material-icons-outlined">
                                        <span>start</span>
                                    </mat-icon>
                                </button>
                            }

                            <!-- User -->
                            <div class="lendio-sidenav-user overflow-x-hidden">
                                <app-sidenav-user [userFullName]="userFullName"
                                                  [organizationName]="organizationName"
                                                  [collapsed]="!lendioSidenav.opened"
                                                  (userLogout)="logout()"
                                                  (userSettings)="goToSettings()">
                                </app-sidenav-user>
                            </div>
                        </mat-sidenav>
                        <mat-sidenav-content class="lendio-sidenav-content">
                            <mat-drawer-container class="h-full" autosize>
                                <!-- Header -->
                                <mat-toolbar data-cy="toolbar"
                                    class="lendio-toolbar"
                                    [class.hidden]="shouldHideToolbar$ | async"
                                    [class.border-visible]="shouldApplyToolbarBottomBorder(pageTitle())">
                                    <mat-toolbar-row class="lendio-toolbar-layout">
                                        <div class="lendio-toolbar-data">
                                            <div class="flex flex-col">
                                                <app-breadcrumb></app-breadcrumb>
                                            <div class="mat-headline-5">
                                                {{ pageTitle() }}
                                                <span class="text-gray-400 mat-subtitle-2">
                                                    {{ secondaryPageTitle() }}
                                                </span>
                                            </div>
                                            </div>
                                        </div>
                                        <span class="lendio-toolbar-spacer"></span>
                                        <div class="lendio-toolbar-actions">
                                            <!-- Notifications -->
                                            @if ((currentUser$ | async)?.isLender()) {
                                                <app-notification-icon-smart [newTheme]="true"></app-notification-icon-smart>
                                            }
                                            <!-- Hijack -->
                                            @if ((currentUser$ | async)?.permissions?.canHijack) {
                                                <app-hijack-smart></app-hijack-smart>
                                            }

                                            <!-- Search -->
                                            @if ((currentUser$ | async)?.isLender() && (currentUser$ | async)?.institution?.id !== 44566) {
                                                <div class="invisible lg:!visible">
                                                    @if (newBusinessSearch$ | async) {
                                                        <search-input></search-input>
                                                    } @else {
                                                        <app-applications-search></app-applications-search>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </mat-toolbar-row>
                                </mat-toolbar>
                                <!-- Alerts -->
                                @if (alerts) {
                                    <div class="toaster-alerts"
                                         [class.new-theme]="newThemeEnabled()"
                                         [class.active]="alerts.length > 0">
                                        @for (alert of alerts; track alert) {
                                            <app-alert [level]="alert.level"
                                                       [message]="alert.message"
                                                       [dismissible]="true"
                                                       (dismissed)="dismissAlert(alert)">
                                            </app-alert>
                                        }
                                    </div>
                                }
                                <div class="module-router-view">
                                    <!-- Router outlet -->
                                    @if (isForbidden) {
                                        <app-not-found></app-not-found>
                                    } @else {
                                        <router-outlet></router-outlet>
                                    }
                                </div>
                                <!-- Notifications drawer -->
                                <mat-drawer class="mat-drawer--notifications"
                                            #notificationsidenav
                                            opened="{{ notificationSideNavExpanded }}"
                                            position="end"
                                            mode="side">
                                    <app-notification-sidenav-smart></app-notification-sidenav-smart>
                                </mat-drawer>
                            </mat-drawer-container>
                        </mat-sidenav-content>
                    </mat-sidenav-container>
                } @else {
                    <ng-template [ngTemplateOutlet]="FeaturesLoading"></ng-template>
                }
            } @else {
                <div class="login-page">
                    <router-outlet></router-outlet>
                </div>
            }
        }
    </span>
}
