import { UploadFileType } from "@app/app/interfaces/upload-file-type.model";

// This list mirrors what we allow users to upload in Pipeline.
// See: https://github.com/LendioDevs/pipeline/blob/77fc9b2d91efdbb45c766a65b0fb46abb1ab3ab6/src/app/models/const/fileTypesAccepted.ts
export const documentsAllowedUploadFileTypes: UploadFileType[] = [
  { mimeType: 'image/bmp', extension: '.bmp' },
  { mimeType: 'text/csv', extension: '.csv' },
  { mimeType: 'application/msword', extension: '.doc' },
  {
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: '.docx',
  },
  { mimeType: 'image/gif', extension: '.gif' },
  { mimeType: 'image/jpeg', extension: '.jpeg' },
  { mimeType: 'image/jpeg', extension: '.jpg' },
  { mimeType: 'application/vnd.oasis.opendocument.chart', extension: '.odc' },
  {
    mimeType: 'application/vnd.oasis.opendocument.presentation',
    extension: '.odp',
  },
  { mimeType: 'application/vnd.oasis.opendocument.text', extension: '.odt' },
  {
    mimeType: 'application/vnd.oasis.opendocument.spreadsheet-template',
    extension: '.ots',
  },
  { mimeType: 'application/pkcs7-signature', extension: '.p7s' },
  { mimeType: 'application/pdf', extension: '.pdf' },
  { mimeType: 'image/png', extension: '.png' },
  { mimeType: 'application/vnd.ms-powerpoint', extension: '.ppt' },
  {
    mimeType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    extension: '.pptx',
  },
  { mimeType: 'image/tiff', extension: '.tif' },
  { mimeType: 'image/tiff', extension: '.tiff' },
  { mimeType: 'text/tab-separated-values', extension: '.tsv' },
  { mimeType: 'text/plain', extension: '.txt' },
  { mimeType: 'text/x-vcard', extension: '.vcf' },
  { mimeType: 'text/x-vcalendar', extension: '.vcs' },
  { mimeType: 'application/vnd.ms-excel', extension: '.xls' },
  {
    mimeType: 'application/vnd.ms-excel.sheet.macroEnabled.12',
    extension: '.xlsm',
  },
  {
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: '.xlsx',
  },
  { mimeType: 'application/vnd.ms-xpsdocument', extension: '.xps' },
  { mimeType: 'audio/wav', extension: '.wav' },
  { mimeType: 'application/json', extension: '.json' },
];
