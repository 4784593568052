import { animate, AnimationMetadata, style, transition, trigger } from "@angular/animations";

export const growAnimation = trigger('grow', [
  transition('* <=> *', [
    style({
      height: '{{startHeight}}px',
      opacity: 0
    }),
    animate('300ms ease')
  ], {
    params: {
      startHeight: 0
    }
  })
]) as AnimationMetadata;
