import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatExpansionPanel} from "@angular/material/expansion";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";
import {FundingDeskRequest} from "@app/app/interfaces/funding-desk-request.model";
import {
  SingleRequestChainsSidebarComponent
} from "@app/app/components/funding-desk/single-request-chains-sidebar/single-request-chains-sidebar.component";

@Component({
  selector: 'app-funding-desk-sidebar',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatExpansionPanel,
    MatFormField,
    MatInput,
    NgForOf,
    NgIf,
    SingleRequestChainsSidebarComponent
  ],
  templateUrl: './funding-desk-sidebar.component.html'
})
export class FundingDeskSidebarComponent implements OnInit {

@ViewChild('fdPanel', { static: true })
  fdPanel: MatExpansionPanel;

@ViewChild('requestTextArea', { static: true })
  requestTextArea: ElementRef;

@Input() fundingDeskRequests: FundingDeskRequest[];
@Output() createNew = new EventEmitter<{ description: string, type: string }>();
@Output() newComment = new EventEmitter<{ description: string, requestId: number }>();

  newRequest = '';
  type = '';
  expandStatus = false;

  constructor() { }

  ngOnInit(): void {}

  createNewRequest(description: string) {
    this.createNew.emit({description, type: this.type});
    this.newRequest = '';
    this.expandStatus = false;
  }

  addNewComment(comment: { description: string, requestId: number }) {
    this.newComment.emit(comment);
  }

  cancelComment() {
    this.expandStatus = false;
    this.newRequest = '';
    this.requestTextArea.nativeElement.blur();
  }

  handleAfterExpand(): void {
    // setTimeout allows the expansion panel animation to finish.
    setTimeout(() => {
    this.requestTextArea.nativeElement.focus();
  });
}

  handleAfterCollapse(): void {
    this.requestTextArea.nativeElement.blur();
  }
}
