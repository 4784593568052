import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Component, Input } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import {
    ColDef,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    GridState,
  } from 'ag-grid-community';
import { DealActivity } from '@app/app/interfaces/deal-activity.model';
import {
    COL_DEF_ACTIVITY_TYPE,
    COL_DEF_CREATED,
    COL_DEF_DESCRIPTION
} from './deal-activities-grid/deal-activities-column-defs';
import {
    dealActivitiesGridConfig,
    dealActivitiesGridDefaultColDef,
    sizeColsToFit
} from './deal-activities-helpers';

@Component({
  selector: 'app-deal-activities',
  standalone: true,
  imports: [
    AgGridAngular,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatMenuModule,
    MatMenuTrigger,
    MatIconModule,
    MatInputModule,
    CommonModule
  ],
  templateUrl: './deal-activities.component.html',
  styleUrls: ['./deal-activities.component.scss'],
})
export class DealActivitiesComponent {

  @Input() dealId: number;
  @Input() dealActivities: DealActivity[];
  private _gridApi: GridApi | undefined;
  public  dealActivitiesGridInitialState: GridState;
  protected readonly dealActivitiesGridConfig = dealActivitiesGridConfig;
  protected readonly colDefDefault = dealActivitiesGridDefaultColDef;
  public colDefActivityType: ColDef = COL_DEF_ACTIVITY_TYPE;
  public colDefCreated: ColDef = COL_DEF_CREATED;
  public colDefDescription: ColDef = COL_DEF_DESCRIPTION;
  public quickFilterValue = '';

  public dealActivitiesGridOptions: GridOptions = Object.assign({}, {
    ...this.dealActivitiesGridConfig,
    defaultColDef: this.colDefDefault,
    columnDefs: [
      this.colDefActivityType,
      this.colDefDescription,
      this.colDefCreated
    ]
  });

  constructor(public dialog: MatDialog) {

    fromEvent(window, 'resize')
      .pipe(debounceTime(250), takeUntilDestroyed())
      .subscribe(() => sizeColsToFit(this._gridApi!));
  }

  onGridReady(event: GridReadyEvent): void {
    this._gridApi = event.api;
    this._gridApi?.applyColumnState({
      state: [ {colId: 'created', sort: 'desc' }]
    });
  }

  onFirstDataRendered(event: FirstDataRenderedEvent): void {
    setTimeout(() => sizeColsToFit(event.api), 500);
  }

  onFilterChanged(event: FilterChangedEvent): void {
    event.api.deselectAll();
  }

  onQuickFilterChange() {
    this._gridApi?.setGridOption('quickFilterText', this.quickFilterValue);
  }

  exportData({ format, onlySelected }: { format: 'csv' | 'excel', onlySelected: boolean }): void {
    if (format === 'csv') {
      this._gridApi?.exportDataAsCsv({ onlySelected, allColumns: true });
    }
    if (format === 'excel') {
      this._gridApi?.exportDataAsExcel({ onlySelected, allColumns: true });
    }
  }
}
