import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  signal,
  WritableSignal
} from '@angular/core';
import { FundingDeskRequest } from 'src/app/interfaces/funding-desk-request.model';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-funding-desk',
  templateUrl: './funding-desk.component.html',
})
export class FundingDeskComponent implements OnInit {

  @ViewChild('fdPanel', { static: true })
  fdPanel: MatExpansionPanel;

  @ViewChild('requestTextArea', { static: false })
  requestTextArea: ElementRef<HTMLTextAreaElement>;

  @Input() fundingDeskRequests: FundingDeskRequest[];
  @Output() createNew = new EventEmitter<{ description: string, type: string }>();
  @Output() newComment = new EventEmitter<{ description: string, requestId: number }>();

  newRequest = '';
  type = '';
  expandStatus = false;
  emptyState: WritableSignal<boolean> = signal(true);

  constructor() { }

  ngOnInit(): void {
    this.checkEmptyState()
  }

  checkEmptyState():void {
    this.emptyState.set(this.fundingDeskRequests.length === 0);
  }

  addAMessage(): void {
    this.emptyState.set(false);
    this.expandStatus = true;
    this.handleAfterExpand();
  }

  handleAfterExpand(): void {
    // setTimeout allows the expansion panel animation to finish.
    setTimeout(() => {
      this.requestTextArea.nativeElement.focus();
    });
  }

  createNewRequest(description: string) {
    this.newRequest = '';
    this.createNew.emit({description, type: this.type});
    this.expandStatus = false;
  }

  addNewComment(comment: { description: string, requestId: number }) {
    this.newComment.emit(comment);
  }

  cancelComment() {
    this.newRequest = '';
    this.expandStatus = false;
    this.checkEmptyState();
  }
}
