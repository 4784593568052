import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { trigger, state, style, animate, transition, useAnimation } from '@angular/animations';
import * as moment from 'moment-timezone';
import { FundingDeskRequest } from 'src/app/interfaces/funding-desk-request.model';
import { fadeIn, fadeOut } from 'ng-animate';
import { AuthState } from '@app/app/store/auth/auth.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-single-request-chain',
  animations: [
    trigger('slideUpDown', [
      state('in', style({height: '*'})),
      transition('* => void', [
        style({height: '*'}),
        animate(150, style({height: 0}))
      ]),
      transition('void => *', [
        style({height: 0}),
        animate(150, style({height: '*'}))
      ])
    ]),
    trigger('fadeOut', [
      transition(':leave',
        useAnimation(fadeOut, {params: {timing: 0.05}})
      )
    ]),
    trigger('fadeIn', [
      transition(':enter',
        useAnimation(fadeIn, {params: {timing: 0.3}})
      )
    ])
  ],
  templateUrl: 'single-request-chain.component.html',
  styleUrls: ['single-request-chain.component.scss']
})
export class SingleRequestChainComponent implements OnChanges, OnInit {

  @ViewChild('commentTextArea', {static: false})
  commentTextArea: ElementRef<HTMLTextAreaElement>;

  @Input() public requestChain: FundingDeskRequest;
  @Input() public requestIndex: number;
  @Output() comment = new EventEmitter<{ description: string, requestId: number }>();

  addNewComment = false;
  moreComments = false;
  comments: any[];
  newComment = '';
  requestTypeList = {
    lender: 'Lender Request',
    fundingDesk: 'Funding Desk Request',
    fundingManager: 'Funding Manager Request'
  };
  addRequestTypeLabel = false;
  requestType = '';

  constructor(private store: Store) { }

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
    this.requestType = this.getRequestType();
    this.addRequestTypeLabel = this.isIndividualRequestType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.requestChain) {
      const sorted = [
        ...changes.requestChain.currentValue.task_comments ?? []
      ].sort((a, b) => {
        return new Date(b.created).getTime()-new Date(a.created).getTime()
      });
      this.comments = sorted;
    }
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }

  createNewComment(description: string) {
    // *BUG* After a new comment is created the component will rerender after 4-5 seconds, this will close the comments thread.
    this.comment.emit({description, requestId: this.requestChain.id});
    const {first, last} = this.store.selectSnapshot(AuthState.user) ?? {}
    const newestComment = {creator_user: { first, last}, created: new Date(), description}
    this.comments = [newestComment, ...this.comments]
    this.newComment = '';
    this.addNewComment = false;
    this.moreComments = true;
  }

  cancelComment() {
    this.newComment = '';
    this.commentTextArea.nativeElement.style.height = '0';
    this.addNewComment = false;
  }

  isIndividualRequestType() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.requestChain.type === 'QC Task' || this.requestTypeList.hasOwnProperty(this.requestChain.fromRole);
  }

  getRequestType() {
    if (this.requestChain.type === 'QC Task') {
      return 'QC Task';
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.requestTypeList[this.requestChain.fromRole];
  }
}
