export interface GetFunnelDealsParams {
  limit: number;
  offset: number;
  checkAan: boolean;
  withDecisioning?: boolean;
  withCustomFilter?: boolean;
  withRequiredDocuments?: boolean;
  isInitialRequest: boolean;
}

export class GetFunnelDeals {
  static readonly type = '[Funnel Deals] GetFunnelDeals';
  constructor(public params: GetFunnelDealsParams) {}
}

export class StopFunnelDealsRequests {
  static readonly type = '[Funnel Deals] StopFunnelDealsRequests';
  constructor() {}
}

export class ClearFunnelDealsStore {
  static readonly type = '[Funnel Deals] ClearFunnelDealsStore';
  constructor() {}
}
