<div data-cy="file-upload-component"
    class="file-upload-container grid place-content-center"
    [class]="inline ? 'displayInline py-4' : 'dialog mx-6 mt-1 mb-4 p-8'"
    [class.loading]="loading"
    [class.error]="errorMessage"
    fileDragNDrop
    (fileDropped)="handleFileDrop($event)"
>
    <div *ngIf="inline" class="file-upload-label">{{ label }}</div>
    <input data-cy="file-upload-input"
        type="file"
        [accept]="allowedMimeTypes.join(',')"
        [multiple]="fileCount > 1"
        #fileInputRef
        id="fileInputRef"
        (change)="handleFileBrowse($event.target.files)"
    />
    <div class="h-full w-full">
        <ng-container *ngIf="selection && !inline">
            <app-dot-loader-scale></app-dot-loader-scale>
        </ng-container>
        <ng-container *ngIf="selection && inline">
            <div *ngFor="let file of selection" class="grid grid-cols-[3fr_1fr_1fr] gap-4 p-4 items-center">
                <div class="file-details-text">{{ file.name }}</div>
                <div class="file-details-text file-size">({{ formatBytes(file.size) }})</div>
                <button data-cy="file-delete-btn" class="remove-file z-0"
                    mat-icon-button
                    color="accent"
                    disableRipple
                    (click)="removeFile(file)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="!selection">
            <div class="grid grid-cols-1 gap-3.5 !font-medium file-details-text placeholder justify-items-center">
                <h3 class="!text-lg flex !my-0 !-mb-1.5">
                    <mat-icon class="material-symbols-outlined">cloud_upload</mat-icon>&nbsp;&nbsp;{{ fileSelectText }}
                </h3>
                <button data-cy="file-upload-btn" mat-flat-button color="primary" class="z-0">{{ buttonText }}</button>
                <div class="font-light instructions text-xs" [class.modal]="!inline">
                    <div class="leading-5 text-center">
                        Upload up to {{ formatBytes(fileSizeLimit) }}<span *ngIf="fileCount <= 1">.</span> {{ fileCount > 1 ? "per file." : ""}}
                    </div>
                    @if (allowedExtensions.length < 5) {
                        <div class="leading-5 text-center">File types accepted: {{ allowedExtensions.join(', ') }}</div>
                    }
                </div>
            </div>
        </ng-container>
        <div *ngIf="errorMessage" class="error-message p-4 pb-0 text-center text-xs">
            {{ errorMessage }}
        </div>
    </div>
</div>
