@if (!(loading$ | async)) {
    @if (!emptyTable()) {
        <mat-table
            [dataSource]="(tableDataSource$ | async)"
            matSort
            [matSortActive]="(sortBy$ | async)"
            [matSortDirection]="(sortDirection$ | async)"
            (matSortChange)="handleSortEvent($event)">
            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay">
            </mat-row>

            <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Date & Time
                </mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <span class="cell-no-wrap">
                        {{data.created | date:'MMM d, y, h:mm a'}}
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="generatedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Generated by</mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <span class="cell-no-wrap">
                        {{ data.generatedBy | defaultValue }}
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="importMonth">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Import Month</mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <span class="cell-no-wrap">
                        {{data.created | date:'MMMM y'}}
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="downloadStatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <div class="pr-2 mt-1">
                        <mat-icon *ngIf="data.downloadStatus" class="material-symbols-outlined green-check" fontSet="material-symbols-outlined">check</mat-icon>
                        <mat-icon *ngIf="!data.downloadStatus" class="material-symbols-outlined red-close" fontSet="material-symbols-outlined">timer</mat-icon>
                    </div>
                    {{ data.downloadStatus ? 'Ready' : 'Pending' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <div *ngIf="data.downloadStatus" class="pr-2 mt-1">
                        <a
                            mat-button
                            (click)="handleDownloadReport(data.id)">
                            Download
                        </a>
                    </div>
                </mat-cell>
            </ng-container>
        </mat-table>
        <lendio-paginator
            #paginator
            (page)="handlePageEvent($event)"
            [length]="(total$ | async)!"
            [pageSize]="(pageSize$ | async)!"
            [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="(pageIndex$ | async)!"
            [showFirstLastButtons]="true">
        </lendio-paginator>
    } @else {
        <app-empty-state [type]="emptyType" [label]="emptyLabelContent" [loading]="loading$ | async">
            <div class="text-center font-normal text-gray-900 mt-2" messageContent>
                Click Import <span class="pointer-emoji">&#9757;</span> to get started.
            </div>
        </app-empty-state>
    }
} @else {
    <div class="text-center loading">
        <app-dot-loader-scale></app-dot-loader-scale>
    </div>
}