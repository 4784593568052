import { State, Action, StateContext, Selector, Store, createSelector } from '@ngxs/store';
import { DocumentsActions as DA } from './documents.actions';
import { tap, catchError } from 'rxjs/operators';
import { DocumentsService } from '../../services/documents.service';
import { Injectable } from '@angular/core';
import { Document } from '../../interfaces/document.model';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';
import { DocumentCategory } from '@app/app/interfaces/document-category.model';
import { AddSnackbarError } from '@app/app/store/snackbar/snackbar.actions';
import { PusherService } from '@app/app/services/pusher.service';
import { orderBy } from 'lodash';

export class DocumentsStateModel {
  documents: Document[];
  current: Document | null;
  categories: DocumentCategory[];
}

@State<DocumentsStateModel>({
  name: 'documents',
  defaults: {
    documents: [],
    current: null,
    categories: []
  }
})

@Injectable()

export class DocumentsState {

  @Selector()
  static documents(state: DocumentsStateModel) {
    return state.documents;
  }

  @Selector()
  static current(state: DocumentsStateModel) {
    return state.current;
  }

  @Selector()
  static categories(state: DocumentsStateModel) {
    return state.categories;
  }

  @Selector()
  static consumerCreditReportDocByContactId(contactId: number) {
    return createSelector([DocumentsState], (state) => {
      const creditDocsForContact = state.documents?.documents.filter( doc => {
        return doc.filename.startsWith(`c-${contactId}`);
      });
      return orderBy(creditDocsForContact, 'created', 'desc')[0];
    });
  }

  @Selector()
  static commercialCreditReportDoc(state: DocumentsStateModel) {
    const creditReportDocs = state.documents.filter(doc => {
      return !doc.filename.startsWith('c-') && doc.category === 'commercialCreditReport';
    });
    return orderBy(creditReportDocs, 'created', 'desc')[0];
  }

  constructor(
    private documentsService: DocumentsService,
    private store: Store,
    private pusherService: PusherService
  ) {}

  // Get a single document by ID
  @Action(DA.GetDocument)
  getDocument(_: StateContext<DocumentsStateModel>, { documentId }: DA.GetDocument) {
    return this.documentsService.getDocument(documentId).pipe(
      catchError((error) => throwError(() => error)),
      tap((documentResponse) => this.store.dispatch(new DA.PatchDocumentState(documentResponse.data)))
    )
  }

  // Get borrower documents.
  @Action(DA.GetBorrowerDocuments)
  getIndex(
    ctx: StateContext<DocumentsStateModel>,
    { borrowerId, borrowerLenderId }: DA.GetBorrowerDocuments
  ) {
    return this.documentsService.getIndex(borrowerId).pipe(
      catchError(err => {
        this.store.dispatch(new AddSnackbarError({
          identifier: 'documentsFetchError',
          subTitle: `Unable to retrieve this business' documents. Please refresh the page to try again.`,
        }));
        ctx.patchState({
          documents: []
        });
        return throwError(err);
      }), tap(response => {
        const documents = borrowerLenderId ? this.filterDocs(response.data, borrowerLenderId) : response.data;
        ctx.patchState({ documents });
      })
    );
  }

  // We support posting/uploading multiple documents. In practice, we'll just
  // call this async for each doc.
  @Action(DA.Post)
  post({ patchState, getState }: StateContext<DocumentsStateModel>, { file, formData }: DA.Post) {
    return this.documentsService.post(file, formData).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to create this document. Please refresh the page to try again.'
        }));
        return throwError(err);
      }),
      tap(response => {
        let document = response.data;
        const state = getState();

        // Add to current list of entities in store.
        const documents = [...state.documents, document];
        patchState({ documents });
      })
    );
  }

  // Update a document.
  @Action(DA.Put)
  put({ patchState, getState }: StateContext<DocumentsStateModel>, payload: DA.Put) {
    const { document } = payload;
    return this.documentsService.put(document).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to update this document. Please refresh the page to try again.'
        })
        );
        return throwError(err);
      }),
      tap(response => {
        let document = response.data;
        const state = getState();
        // Update within store and make current.
        const documents = state.documents.map(d => {
          if (d.id === document.id) {
            return document;
          } else {
            return d;
          }
        });
        patchState({
          documents,
          current: document
        });
      })
    );
  }

  @Action(DA.PatchDealDocument)
  patchDealDocument({ dispatch, getState, patchState }: StateContext<DocumentsStateModel>, { dealId ,documentUpdates }: DA.PatchDealDocument) {
    return this.documentsService.patchDealDocument(dealId, documentUpdates).pipe(
      catchError((err) => {
        // Some kind of thing to UI
        dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to edit document.'
        }))
        // TODO: Log error to Rollbar
        return throwError(() => err);
      }),
      tap((response) => {
        let document = response.data;
        const state = getState();
        // Update within store and make current.
        const documents = state.documents.map(d => {
          if (d.id === document.id) {
            return document;
          } else {
            return d;
          }
        });
        patchState({
          documents,
          current: document
        });
      })
    );
  }

  @Action(DA.Delete)
  delete(ctx: StateContext<DocumentsStateModel>, payload: DA.Delete) {
    const { id } = payload;
    return this.documentsService.delete(id).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to delete this document. Please refresh the page to try again.'
        })
        );
        return throwError(err);
      }),
      tap(() => {
        // Remove document in local store list and ensure current is null.
        const state = ctx.getState();
        const documents = state.documents.filter(d => d.id !== id);
        ctx.patchState({
          documents,
          current: null
        });
      }),
    );
  }

  @Action(DA.DeleteDealDocument)
  deleteDealDocument(ctx: StateContext<DocumentsStateModel>, { dealId, documentId }: DA.DeleteDealDocument) {
    return this.documentsService.deleteDealDocument(dealId, documentId).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to delete this document.'
        })
        );
        return throwError(() => err);
      }),
      tap(() => {
        const state = ctx.getState();
        const documents = state.documents.filter(d => d.id !== documentId);
        ctx.patchState({
          documents,
          current: null
        });
      }),
    );
  }

  // Get deal documents.
  @Action(DA.GetDealDocuments)
  getDocuments(
    ctx: StateContext<DocumentsStateModel>,
    { dealId, borrowerLenderId }: DA.GetDealDocuments
  ) {
    return this.documentsService.getDocuments(dealId).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to retrieve documents for this deal. Please refresh the page to try again.'
        }));
        return throwError(err);
      }),
      tap(response => {
        const documents = borrowerLenderId ? this.filterDocs(response.data, borrowerLenderId) : response.data;
        ctx.patchState({ documents });
      })
    );
  }

  // Clear out documents between borrowers/businesses or deals.
  @Action(DA.ClearDocumentsState)
  clearDocuments({ patchState }: StateContext<DocumentsStateModel>, {}: DA.ClearDocumentsState) {
    patchState({ documents: [] });
  }

  // Get the public/external document categories (Type in UI).
  @Action(DA.GetDocumentCategories)
  getDocumentCagegories({ patchState }: StateContext<DocumentsStateModel>) {
    return this.documentsService.getDocumentCategories().pipe(
      catchError(err => {
        return throwError(err);
      }),
      tap(response => {
        patchState( {
          categories: response.data
        })
      })
    )
  }

  /**
   * Filter business docs.
   *
   * @param Document[] documents Unfiltered doc list from api
   * @param number|null lenderId For filtering business docs
   */
  filterDocs(documents: Document[], lenderId: number) {
    const removeDocCategoryList = [
      'applicationUnsigned',
      'applicationSigned'
    ];
    const lendioLenderId = 44566;
    return lenderId === lendioLenderId
      ? documents
      : documents.filter( doc => !removeDocCategoryList.includes(doc.category));
  }

  @Action(DA.PatchDocumentState)
  patchDocumentState({ patchState, getState }: StateContext<DocumentsStateModel>, { document }: DA.PatchDocumentState) {
    const state = getState();
    const documents = state.documents.map(d => {
      if (d.id === document.id) {
        return document;
      } else {
        return d;
      }
    });

    if (!documents.find(d => d.id === document.id)) {
      documents.push(document);
    }

    patchState({
      documents
    });
  }

  @Action(DA.SubscribeToDocumentStateUpdates)
  subscribeToDocumentStateUpdates({ }: StateContext<DocumentsStateModel>, { borrowerId }: DA.SubscribeToDocumentStateUpdates) {
    this.pusherSubscribe(borrowerId);
  }

  @Action(DA.UnsubscribeFromDocumentStateUpdates)
  unsubscribeFromImportStateUpdates({ }: StateContext<DocumentsStateModel>, { borrowerId }: DA.UnsubscribeFromDocumentStateUpdates) {
    this.pusherUnsubscribe(borrowerId);
  }

  pusherSubscribe(borrowerId: number) {
    this.pusherService.subscribe({
      name: `document-scan-status-is-clean-${borrowerId}`,
      auth: false,
      handler: (event: any, document: Document) => {
        if(event === 'document-scan-status-is-clean'){
          this.handlePusherMessage(document);
        }
        return;
      }
    });
  }

  pusherUnsubscribe(borrowerId: number) {
    this.pusherService.unsubscribe({
      name: `document-scan-status-is-clean-${borrowerId}`,
      auth: false,
      handler: () => {}
    });
  }

  handlePusherMessage(document: Document) {
    this.store.dispatch(new DA.GetDocument(document.id));
  }

}
