import { Injectable } from '@angular/core';
import { SaasFeature, EarlyAccessFeature, EarlyAccessFeatureEnrollment, EarlyAccessFeatureRequest } from '@app/app/interfaces/saas-feature.model';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { ClearSaasFeaturesStore, GetSaasFeatures, UpdateEarlyAccessFeatureEnrollments } from './saas-features.actions';
import { SaasFeaturesService } from '@app/app/services/saas-features.service';
import { EarlyAccessFeatureService } from '@app/app/components/settings/early-access/early-access-feature.service';
import { catchError, filter, of, tap, forkJoin } from 'rxjs';
import { Permission } from '@app/app/interfaces/permission.model';
import { Role } from '@app/app/interfaces/role.model';
import { AuthState } from '../auth/auth.state';
import { LogInToThoughtSpot } from '../thoughtspot-reports/thoughtspot-reports.actions';

export class SaasFeaturesStateModel {
    saasFeatures: SaasFeature[];
    earlyAccessFeatures: EarlyAccessFeature[];
    earlyAccessFeatureEnrollments: EarlyAccessFeatureEnrollment[];
    permissions: Permission[];
    roles: Role[];
    initialLoadComplete: boolean;
    isLenderUser: boolean = false;
    childAffiliateTypes: Array<string> = [];
}

@State<Partial<SaasFeaturesStateModel>>({
    name: 'SaasFeatures',
    defaults: {
        saasFeatures: [],
        earlyAccessFeatures: [],
        earlyAccessFeatureEnrollments: [],
        permissions: [],
        roles: [],
        initialLoadComplete: false,
        isLenderUser: false
    }
})

@Injectable()
export class SaasFeaturesState {
  @Selector()
  static saasFeatures(state: SaasFeaturesStateModel) {
      return state.saasFeatures;
  }

  @Selector()
  static permissions(state: SaasFeaturesStateModel) {
      return state.permissions;
  }

  @Selector()
  static earlyAccessFeatures(state: SaasFeaturesStateModel) {
    const earlyAccessFeaturesWithEnrollmentStatus =  state.earlyAccessFeatures.map((earlyAccessFeature: EarlyAccessFeature) => {
      const featureEnrollment = state.earlyAccessFeatureEnrollments.find((enrollment) => enrollment.earlyAccessFeatureId === earlyAccessFeature.id);

      return {
        ...earlyAccessFeature,
        userEnrollment: featureEnrollment && featureEnrollment.enrollmentType === 'user',
        disabled: featureEnrollment ? !!featureEnrollment.disabled : true
      };
    });

    return earlyAccessFeaturesWithEnrollmentStatus;
  }

  @Selector()
  static earlyAccessFeatureEnrollments(state: SaasFeaturesStateModel) {
    return state.earlyAccessFeatureEnrollments;
  }

  @Selector()
  static saasPermitted(saasFeatureAlias: string, permissionAlias?: string) {
    return createSelector([SaasFeaturesState], (state) => {
      const saasState = state?.SaasFeatures,
        features = saasState?.saasFeatures,
        permissions = saasState?.permissions;

      const featureNames = features?.map((saasFeature: SaasFeature) => saasFeature.alias),
        permissionNames = permissions?.map( (permission: Partial<{name: string}>) => permission.name);

      const hasFeature = featureNames?.includes(saasFeatureAlias),
        hasPermission = (!permissionAlias || permissionNames?.includes( permissionAlias));
      return hasFeature && hasPermission;
    })
  }

  /*
   * Check if we have a feature that requires auth with thoughtspot
   */
  shouldLogInToThoughtSpot(saasFeatures: SaasFeature[]) {
    const tsFeatures = [
      'reportsList',
      'dashboardPartnerBase',
      'dashboardLenderBase',
      'dashboardAffiliateBase'
    ];
    const subscribedTsFeatures = saasFeatures.filter((sf) => tsFeatures.includes(sf.alias));

    if(subscribedTsFeatures.length > 0) {
      this.store.dispatch(new LogInToThoughtSpot(subscribedTsFeatures));
    }
  }

  @Selector()
  static roles(state: SaasFeaturesStateModel) {
      return state.roles;
  }

  @Selector()
  static initialLoadComplete(state: SaasFeaturesStateModel) {
    return state.initialLoadComplete;
  }

  @Selector()
  static featuresConfig(state: SaasFeaturesStateModel) {
    const permissionNames = this.getPermissionNames(state),
      featureNames = this.getFeatureNames(state);

    return [
      {
        name: 'team',
        label: 'Team',
        featureAlias: 'administrationManageTeam',
        featurePermission: 'lpxAdministrationManageTeam.view',
        link: '/administration/team',
      },
      {
        name: 'import',
        label: 'Import',
        featureAlias: 'administrationImport',
        featurePermission: 'lpxAdministrationImport.view',
        link: '/administration/import'
      },
      {
        name: 'api',
        label: 'API',
        featureAlias: 'administrationGetApiCreds',
        featurePermission: 'lpxAdministrationGetApiCreds.view',
        link: '/administration/api'
      },
      {
        name: 'integrations',
        label: 'Integrations',
        featureAlias: 'administrationIntegrations',
        featurePermission: 'lpxAdministrationIntegrations.view',
        link: '/administration/integrations'
      },
      {
        name: 'embedded-app',
        label: state.isLenderUser ? 'Branding': 'Embedded app',
        featureAlias: 'administrationEmbeddedConfiguration',
        featurePermission: 'lpxAdministrationEmbeddedConfiguration.view',
        link: '/administration/embedded-app/installation',
        affiliateMediumRequired: 'Embedded'
      },
      {
        name: 'intelligent-app',
        label: state.isLenderUser ? 'Branding' : 'Intelligent Lending',
        featureAlias: 'administrationIntelligentConfiguration',
        featurePermission: 'lpxAdministrationIntelligentConfiguration.view',
        link: '/administration/intelligent-lending/branding',
        affiliateMediumRequired: 'Intelligent Lending'
      }
    ].map( featureConfig => {
      return {
        ...featureConfig,
        permitted: permissionNames.includes( featureConfig.featurePermission )
          && featureNames.includes( featureConfig.featureAlias )
          /**
           * A lender / partner requires the correct corresponding affiliate record(s) in addition to
           * feature & permission for embedded / IL customizations to be made visible.
           */
          && ( !featureConfig.affiliateMediumRequired || state.childAffiliateTypes.includes(featureConfig.affiliateMediumRequired) )
      };
    })
  }

  constructor(
    private saasFeaturesService: SaasFeaturesService,
    private earlyAccessFeatureService: EarlyAccessFeatureService,
    private store: Store,
  ) {}

  @Action(GetSaasFeatures)
  getSaasFeatures(
    { patchState }: StateContext<SaasFeaturesStateModel>,
    {}: GetSaasFeatures
  ) {
    return this.saasFeaturesService.getSaasFeatures().pipe(
      tap(({ data })  => {
        patchState({
          saasFeatures: data.saasFeatures as SaasFeature[],
          permissions: data.permissions as Permission[],
          earlyAccessFeatures: data.earlyAccessFeatures as EarlyAccessFeature[],
          earlyAccessFeatureEnrollments: data.earlyAccessFeatureEnrollments as EarlyAccessFeatureEnrollment[],
          roles: data.roles as Role[],
          initialLoadComplete: true,
          isLenderUser: this.store.selectSnapshot(AuthState.user)?.isLender(),
          childAffiliateTypes: this.store.selectSnapshot(AuthState.user)?.getChildAffiliateTypes()
        });
        this.shouldLogInToThoughtSpot(data.saasFeatures)
      }),
      catchError((error) => {
        patchState({
          saasFeatures: [],
          permissions: [],
          roles: [],
          initialLoadComplete: true,
        });
        throw error;
      }),
    );
  }

  @Action(ClearSaasFeaturesStore)
  clearSaasFeaturesStore(
    { patchState }: StateContext<SaasFeaturesStateModel>,
    {}: ClearSaasFeaturesStore) {
      patchState({
        saasFeatures: [],
        permissions: [],
        roles: [],
        initialLoadComplete: false,
      });
  }

  @Action(UpdateEarlyAccessFeatureEnrollments)
  updateEarlyAccessFeatureEnrollments({patchState}: StateContext<SaasFeaturesStateModel>, features: any)
  {
    return this.earlyAccessFeatureService.upsertEarlyAccessFeatures(features)
      .pipe(
        tap(({ data })  => {
          patchState({
            saasFeatures: data.saasFeatures as SaasFeature[],
            permissions: data.permissions as Permission[],
            earlyAccessFeatures: data.earlyAccessFeatures as EarlyAccessFeature[],
            earlyAccessFeatureEnrollments: data.earlyAccessFeatureEnrollments as EarlyAccessFeatureEnrollment[],
            roles: data.roles as Role[],
            initialLoadComplete: true,
            isLenderUser: this.store.selectSnapshot(AuthState.user)?.isLender(),
            childAffiliateTypes: this.store.selectSnapshot(AuthState.user)?.getChildAffiliateTypes()
          });
          this.shouldLogInToThoughtSpot(data.saasFeatures)
        }),
        catchError((error) => {
          patchState({
            saasFeatures: [],
            permissions: [],
            roles: [],
            initialLoadComplete: true,
          });
          throw error;
        })
      );
  }

  private static getPermissionNames( state: SaasFeaturesStateModel ) {
    return state.permissions?.map( permission => permission?.name );
  }

  private static getFeatureNames( state: SaasFeaturesStateModel ) {
    return state.saasFeatures.map( feature => feature?.alias );
  }
}
