import '../polyfills';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { CurrencyPipe } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SessionService } from './auth/session.service';
import { TokenInterceptor } from './auth/token.interceptor';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { CsvPageModule } from './components/csv-page/csv-page.module';
import { AlertModule } from './components/alert/alert.module';
import { NavButtonComponent } from './components/layout/nav-button/nav-button.component';
import { LoanProductModule } from './components/loan-product/loan-product.module';
import { NotificationsModule } from './components/notifications/notifications.module';
import { ReportsModule } from './components/reports/reports.module';
import { ImportsModule } from './components/lender-imports/imports.module';
import { AdministrationModule } from './components/administration/administration.module';

import { QuillModule } from 'ngx-quill';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './rollbar';

import { environment } from '../environments/environment';
import { ApplicationDetailsModule } from './components/applications/application-details/application-details.module';
import { SettingsModule } from './components/settings/settings.module';

import { AdverseActionNoticeState } from './store/adverse-action-notice/adverse-action-notice.state';
import { ApplicationDetailsState } from './store/application-details/application-details.state';
import { ApplicationsListState } from './store/applications-list/applications-list.state';
import { ApprovalsState } from './store/offers/approvals.state';
import { RenewalsListState } from './store/renewals-list/renewals-list.state';
import { ApplicationsSearchComponent } from './components/layout/applications-search/applications-search.component';
import { ClientsModule } from './components/clients/clients.module';
import { ContactsState } from './store/contacts/contacts.state';
import { ContractRequestsState } from './store/contract-requests/contract-requests.state';
import { CsvState } from './store/csv/csv.state';
import { DealActivitiesState } from './store/deal-activities/deal-activities.state';
import { DocumentsState } from './store/documents/documents.state';
import { FundingDeskRequestsState } from './store/funding-desk/funding-desk-requests.state';
import { GlobalAlertsState } from './store/global-alerts/global-alerts.state';
import { HijackComponent } from './components/layout/hijack/hijack/hijack.component';
import { HijackSmartComponent } from './components/layout/hijack/hijack-smart/hijack-smart.component';
import { LabelsState } from './store/labels/labels.state';
import { LenderStatisticsState } from './store/lender-statistics/lender-statistics.state';
import { LocaleSettingService } from './services/locale-setting-service';
import { LogoutComponent } from './components/logout/logout.component';
import { NotFoundComponent } from './components/errors/not-found/not-found.component';
import { NotificationService } from './services/notification.service';
import { NotificationsState } from './store/notifications/notifications.state';
import { OffersState } from './store/offers/offers.state';
import { PortalUsersState } from './store/portal-users/portal-users-state.service';
import { PusherService } from './services/pusher.service';
import { SettingsState } from './store/settings/settings.state';
import { WebhooksState } from './store/webhooks/webhooks.state';
import { LaserProState } from "@app/app/store/laser-pro/laser-pro.state";
import { LenderApprovalBenefitsState } from 'src/app/store/lender-approval-benefits/lender-approval-benefits.state';
import { OpportunitiesState } from 'src/app/store/opportunities/opportunities.state';
import { OpportunitiesGridState } from 'src/app/store/opportunitiesGrid/opportunities-grid.state';
import { EmbeddedState } from './store/embedded/embedded.state';
import { IntelligentState } from './store/intelligent/intelligent.state';
import { ThoughtspotReportsState } from './store/thoughtspot-reports/thoughtspot-reports.state';
import {
  SidenavUserComponent
} from '@app/app/components/lendio-angular-material-theme/sidenav-user/sidenav-user.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TitleStrategy } from '@angular/router';
import { PageTitleService } from '@app/app/services/page-title.service';
import { ImportsState } from './store/lender-imports/import.state';
import { ImportDetailsState } from './store/import-details/import-details.state';
import { MatSortModule } from '@angular/material/sort';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DecisioningState } from '@app/app/store/decisioning/decisioning.state';
import { DealNotesState } from '@app/app/store/deal-notes/deal-notes.state';
import { LoadersModule } from '@app/app/components/loaders/loaders.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfettiService } from './services/confetti.service';
import { BusinessesListState } from './store/businesses/businesses-list.state';
import { BusinessesModule } from './components/businesses/businesses.module';
import { BusinessState } from './store/businesses/business.state';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { SaasFeaturesState } from './store/saas-features/saas-features.state';
import { SaasFeaturesService } from './services/saas-features.service';
import { DecisioningSnackbarComponent } from './components/decisioning-snackbar/decisioning-snackbar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuthState } from '@app/app/store/auth/auth.state';
import { LenderUserState } from '@app/app/store/lender-user/lender-user.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { EsignatureState } from './store/esignature/esignature.state';
import { ColumnSortState } from './store/column-sort/column-sort.state';
import { ScopeState } from '@app/app/store/scopes/scope.state';
import { FiltersState } from './store/filters/filters.state';
import { DeadDispositionsState } from './store/dead-dispositions/dead-dispositions.state';
import { FinancesState } from '@app/app/store/finances/finances.state';
import { LetDirectiveModule } from '@app/app/directives/let/let.directive.module';
import { SearchState } from '@app/app/store/search/search.state';
import { SearchModule } from '@app/app/components/search/search.module';
import { SnackbarState } from '@app/app/store/snackbar/snackbar.state';
import { IntegrationsState } from '@app/app/store/integrations/integrations.state';
import { IntegrationActivitiesState } from '@app/app/store/integrations/integration-activities.state';
import {
  SaasNewFeatureBadgeComponent
} from "@app/app/components/lendio-angular-material-theme/saas-new-feature-badge/saas-new-feature-badge.component";
import { LoadingModule } from './components/loading/loading.module';
import { TemplateState } from '@app/app/store/templates/template.state';
import Quill from 'quill';
import { LendioDivBlotBlock } from '@app/app/libs/LendioDivBlotBlock';
import { FunnelDealsState } from './store/funnel-deals/funnel-deals.state';
import { FunnelGridState } from '@app/app/store/funnel-deals-grid/funnel-deals-state';
import { FunnelViewsState } from './store/funnel-views/funnel-views.state';
import { TrackingState } from './store/tracking/tracking.state';
import { LenderTasksState } from './store/lender-tasks/lender-tasks.state';
// Comment here for reference. See NgxMask* comments below.
// import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

// allows for setting references to window while debugging
declare global {
  interface Window {
    [key: string]: any;
  }
}

Quill.register(LendioDivBlotBlock);

@NgModule({
  declarations: [
    AppComponent,
    NavButtonComponent,
    ApplicationsSearchComponent,
    HijackSmartComponent,
    HijackComponent,
    NotFoundComponent,
    LogoutComponent,
    SidenavUserComponent,
    BreadcrumbComponent,
    ConfirmDialogComponent,
    DecisioningSnackbarComponent,
  ],
  bootstrap: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CsvPageModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    NotificationsModule,
    ApplicationDetailsModule,
    BusinessesModule,
    LayoutModule,
    ClientsModule,
    AuthModule,
    AlertModule,
    SettingsModule,
    LoadingModule,
    QuillModule.forRoot(),
    NgxsModule.forRoot([
      // Keep it alphabetized...
      AdverseActionNoticeState,
      ApplicationDetailsState,
      ApplicationsListState,
      ApprovalsState,
      AuthState,
      BusinessState,
      BusinessesListState,
      ColumnSortState,
      ContactsState,
      ContractRequestsState,
      CsvState,
      DeadDispositionsState,
      DealActivitiesState,
      DealNotesState,
      DecisioningState,
      DocumentsState,
      EmbeddedState,
      EsignatureState,
      FiltersState,
      FinancesState,
      FundingDeskRequestsState,
      FunnelDealsState,
      FunnelGridState,
      FunnelViewsState,
      GlobalAlertsState,
      ImportDetailsState,
      ImportsState,
      IntegrationActivitiesState,
      IntegrationsState,
      IntelligentState,
      LaserProState,
      LabelsState,
      LenderApprovalBenefitsState,
      LenderStatisticsState,
      LenderUserState,
      NotificationsState,
      OffersState,
      OpportunitiesGridState,
      OpportunitiesState,
      PortalUsersState,
      RenewalsListState,
      SaasFeaturesState,
      ScopeState,
      SearchState,
      SettingsState,
      SnackbarState,
      LenderTasksState,
      TrackingState,
      TemplateState,
      ThoughtspotReportsState,
      WebhooksState,
    ], {
      developmentMode: !environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment || environment.disableNgxsConsoleLogging || environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: !environment || environment.production,
      maxAge: 5000,
    }),
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatSortModule,
    DashboardModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    LoadersModule,
    MatTooltipModule,
    MatDialogModule,
    LoanProductModule,
    ReportsModule,
    ImportsModule,
    AdministrationModule,
    MatProgressBarModule,
    LetDirectiveModule,
    SearchModule,
    SaasNewFeatureBadgeComponent
  ],
  providers: [
    PusherService,
    MatSnackBar,
    SessionService,
    NotificationService,
    ConfettiService,
    SaasFeaturesService,
    CurrencyPipe,
    {
      provide: LOCALE_ID,
      deps: [LocaleSettingService],
      useFactory: (localeSettingService: LocaleSettingService) => localeSettingService.getLocale(),
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      deps: [LocaleSettingService],
      useFactory: (localeSettingService: LocaleSettingService) => localeSettingService.getCurrencyCode(),
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'fill'},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: RollbarErrorHandler,
    },
    {
      provide: RollbarService,
      useFactory: rollbarFactory,
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {disabled: true},
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleService,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
}
