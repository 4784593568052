import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap, throwError } from 'rxjs';
import {
  AddNewLenderTask,
  ClearLenderTasksState,
  CreateOrUpdateLenderTaskEnrollment,
  GetLenderTasks,
  SubscribeToLenderTaskUpdates,
  UnsubscribeFromLenderTaskUpdates,
  UpdateLenderTask,
} from './lender-tasks.actions';
import { LenderTasksService } from '@app/app/services/lender-tasks.service';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { LenderTask } from '@app/app/interfaces/lender-task.model';
import { PusherService } from '@app/app/services/pusher.service';
import { AuthState } from '../auth/auth.state';

export class LenderTasksStateModel {
  lenderTasks: LenderTask[];
}

@State<LenderTasksStateModel>({
  name: 'tasks',
  defaults: {
    lenderTasks: [],
  },
})
@Injectable()
export class LenderTasksState {
  constructor(
    private _lenderTasksService: LenderTasksService,
    private _store: Store,
    private pusherService: PusherService
  ) {}

  @Selector()
  static lenderTasks(state: LenderTasksStateModel) {
    return state.lenderTasks;
  }

  @Action(GetLenderTasks)
  getLenderActionItems(
    { patchState }: StateContext<LenderTasksStateModel>,
    {}: GetLenderTasks
  ) {
    return this._lenderTasksService.getTasks().pipe(
      catchError((error) => {
        this._store.dispatch(
          new CreateNewAlert({
            level: 'error',
            message: 'Could not fetch tasks.',
          })
        );
        return throwError(() => error);
      }),
      tap((response) => {
        patchState({ lenderTasks: response.data });
      })
    );
  }

  @Action(CreateOrUpdateLenderTaskEnrollment)
  createOrUpdateTaskEnrollment(
    { getState, patchState }: StateContext<LenderTasksStateModel>,
    { lenderTaskEnrollmentParams }: CreateOrUpdateLenderTaskEnrollment
  ) {
    return this._lenderTasksService
      .createOrUpdateTaskEnrollment(lenderTaskEnrollmentParams)
      .pipe(
        catchError((error) => {
          this._store.dispatch(
            new CreateNewAlert({
              level: 'error',
              message: 'Could not create or update task enrollment.',
            })
          );
          return throwError(() => error);
        }),
        tap((response) => {
          // First check for a matching task
          const state = getState();
          const matchingTaskIndex = state.lenderTasks?.findIndex(
            (task) => task.id === response.data?.lenderActionItemId
          );
          if (matchingTaskIndex === -1 || matchingTaskIndex === undefined) {
            this._store.dispatch(
              new CreateNewAlert({
                level: 'error',
                message: 'Could not find matching task to update.',
              })
            );
            return;
          }

          // Update matching task’s read, assigned, and assignments properties
          const updatedTasks = state.lenderTasks?.map((task) => {
            if (task.id === response.data?.lenderActionItemId) {
              const lenderUserId = lenderTaskEnrollmentParams.userId;
              let updatedAssignments = task.assignments
                ? [...task.assignments]
                : [];

              if (response.data?.assigned) {
                if (!updatedAssignments.includes(lenderUserId)) {
                  updatedAssignments.push(lenderUserId);
                }
              } else {
                if (updatedAssignments.includes(lenderUserId)) {
                  updatedAssignments = updatedAssignments.filter(id => id !== lenderUserId);
                }
              }

              return {
                ...task,
                assigned: response.data?.assigned ? Date.now().toLocaleString() : null,
                read: response.data?.read ? Date.now().toLocaleString() : null,
                assignments: updatedAssignments,
              };
            }
            return task;
          });

          patchState({ lenderTasks: updatedTasks });
        })
      );
  }

  @Action(ClearLenderTasksState)
  ClearTasksState(
    { patchState }: StateContext<LenderTasksStateModel>,
    {}: ClearLenderTasksState
  ) {
    patchState({ lenderTasks: [] });
  }

  @Action(AddNewLenderTask)
  addNewLenderTask({ patchState, getState }: StateContext<LenderTasksStateModel>, { lenderTask }: AddNewLenderTask) {
    const state = getState();
    const lenderTasks = state.lenderTasks ?? [];

    const lenderUserId = this._store.selectSnapshot(AuthState.user).id;

    if (lenderUserId && lenderTask.assignments?.includes(lenderUserId)) {
      lenderTask.assigned = Date.now().toLocaleString();
    }

    patchState({ lenderTasks: [ ...lenderTasks, lenderTask ]});
  }

  @Action(UpdateLenderTask)
  UpdateLenderTask({ patchState, getState }: StateContext<LenderTasksStateModel>, { lenderTask }: UpdateLenderTask) {
    const state = getState();
    const matchingTaskIndex = state.lenderTasks?.findIndex(
      (task) => task.id === lenderTask.id
    );

    if (matchingTaskIndex === -1) {
      return;
    }

    if (lenderTask.completed) {
      const updatedLenderTasks = state.lenderTasks?.filter(task => task.id !== lenderTask.id);

      return patchState({ lenderTasks: updatedLenderTasks });
    }
  }
  @Action(SubscribeToLenderTaskUpdates)
  subscribeToLenderTaskUpdates({ }: StateContext<LenderTasksStateModel>, { }: UnsubscribeFromLenderTaskUpdates) {
    this.pusherSubscribe();
  }

  @Action(UnsubscribeFromLenderTaskUpdates)
  unsubscribeFromLenderTaskUpdates({ }: StateContext<LenderTasksStateModel>, { }: UnsubscribeFromLenderTaskUpdates) {
    this.pusherUnsubscribe();
  }

  pusherSubscribe() {
    this.pusherService.subscribe({
      name: `lender-action-items-${this._store.selectSnapshot(AuthState.user)?.institution?.id}`,
      auth: false,
      handler: (event: any, lenderTask: LenderTask) => {
        if (event === 'action-item-updated') {
          this.handlePusherMessage(event, lenderTask);
        }
        if (event === 'action-item-created') {
          this.handlePusherMessage(event, lenderTask);
        }
      }
    });
  }

  pusherUnsubscribe() {
    this.pusherService.unsubscribe({
      name: `lender-action-items-${this._store.selectSnapshot(AuthState.user)?.institution?.id}`,
      auth: false,
      handler: () => {
        return;
      }
    });
  }

  handlePusherMessage(type: string, lenderTask: LenderTask) {
    if (type === 'action-item-created') {
      this._store.dispatch(new AddNewLenderTask(lenderTask));
    }
    if (type === 'action-item-updated') {
      this._store.dispatch(new UpdateLenderTask(lenderTask));
    }
  }
}
