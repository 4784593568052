import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GetAuthUser, SetAuthAlert } from '@app/app/store/auth/auth.actions';
import { Store } from '@ngxs/store';
import { AuthService } from "@app/app/auth/auth.service";
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Alert } from '@app/app/interfaces/alert.model';
import { LoadingService } from '@app/app/services/loading.service';

@Component({
  selector: 'app-mfa-code-verification',
  templateUrl: './mfa-code-verification.component.html',
  styleUrls: ['./mfa-code-verification.component.scss'],
})
export class MfaCodeVerificationComponent implements OnInit, OnDestroy {
  transactionId: string;
  skipChallengeMessage?: string;
  countdown: number = 30;
  countdownSubscription: Subscription;
  alert: Alert | null = null;
  skipChallenge = false;
  codeInvalid = false;
  code: string;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private store: Store,
    private auth: AuthService,
    private router: Router,
    private loadingService: LoadingService,
  ) {
  }

  ngOnInit(): void {
    this.startCountdown();
    this.transactionId = this.route.snapshot.queryParamMap.get('transactionId') ?? '';
    this.skipChallengeMessage = this._buildSkipChallengeMessage();
    if (!this.transactionId) {
      this.router.navigate(['/auth/login']);
    }
  }

  ngOnDestroy() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  dismissAlert() {
    this.alert = null;
  }

  startCountdown() {
    this.countdownSubscription = interval(1000)
      .pipe(take(this.countdown))
      .subscribe(value => this.countdown = this.countdown - 1,
        error => {},
        () => {});
  }

  resetCountdown() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    this.countdown = 30;
    this.startCountdown();
  }

  verifyCode(code: string) {
    this.codeInvalid = false;
    if (code === undefined || code.length < 6) {
      return;
    }
    const transactionId = this.transactionId;
    const rememberDevice = this.skipChallenge;
    this.auth.verifyMfaCode({code, transactionId, rememberDevice}).pipe(take(1)).subscribe({
      next: (res) => {
        switch (res.data.status) {
          case 'success':
            this.store.dispatch(new GetAuthUser());
            this.loadingService.setLoading(true);
            break;
          case 'passwordExpired':
            this.router.navigate(
              ['/auth/password-expired'], {
                queryParams: {
                  transactionId,
                }
              }
            );
            break;
          default:
            this.alert = {
              level: 'error',
              message: 'Verification failed.'
            };
            this.store.dispatch(new SetAuthAlert(this.alert));
            this.router.navigate(['/auth/login']);
            break;
        }
      },
      error: (error) => {
        const message = error.error?.errors?.includes('Invalid code')
          ? 'Invalid code. Please try again.'
          : error.error?.errors?.includes('Invalid MFA code: Max check attempts reached')
            ? 'Code verification attempt limit reached.'
            : 'Verification failed.';
        this.codeInvalid = true;
        this.resetCountdown();
        this.alert = {
          level: 'error',
          message,
        };
        if (message !== 'Invalid code. Please try again.') {
          this.store.dispatch(new SetAuthAlert(this.alert));
          this.router.navigate(['/auth/login']);
        }
      }
    });
  }

  resendCode() {
    this.resetCountdown();
    this.auth.mfaChallenge(this.transactionId, true).pipe(take(1)).subscribe({
      next: () => {
        this.alert = {
          level: 'info',
          message: 'Code has been resent.'
        };
      },
      error: () => {
        this.alert = {
          level: 'error',
          message: 'Failed to send code. Please try again.'
        };
      }
    });
  }

  private _buildSkipChallengeMessage() {
    const mfaLifespanInMinutes = Math.floor(parseInt(this.route.snapshot.queryParamMap.get('mfaSuccessLifespan') ?? '0', 10) / 60000);
    // If more than one day, convert to days and hours
    if (mfaLifespanInMinutes > 1440) {
      const days = Math.floor(mfaLifespanInMinutes / 1440);
      const remainingHours = Math.floor((mfaLifespanInMinutes % 1440) / 60);
      const hoursPart = remainingHours > 0 ? ` and ${remainingHours} hour${remainingHours > 1 ? 's' : ''}` : '';
      return `${days.toLocaleString()} day${days > 1 ? 's' : ''}${hoursPart}`;
    }
    // If more than one hour, convert to hours and minutes
    if (mfaLifespanInMinutes > 60) {
      const hours = Math.floor(mfaLifespanInMinutes / 60);
      const remainingMinutes = Math.floor(mfaLifespanInMinutes % 60);
      const minutesPart = remainingMinutes > 0 ? ` and ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}` : '';
      return `${hours} hour${hours > 1 ? 's' : ''}${minutesPart}`;
    }
    // If more than one minute, convert to minutes
    if (mfaLifespanInMinutes >= 1) {
      return `${mfaLifespanInMinutes} minute${mfaLifespanInMinutes > 1 ? 's' : ''}`;
    }
    // If less than one minute, return nothing
    return;
  }
}
