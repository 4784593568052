import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[appFocusOnInit]',
  standalone: true
})
export class FocusOnInitDirective implements OnInit {

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
    this.element.nativeElement.focus();
  }

}
