<div class="p-4 new-theme-typography">

    <div>
        <ng-container *ngIf="(integration$ | async) as integration">
            <mat-card appearance="outlined"
                    class="mb-4 p-4"
                    *appLet="statusMap[integration.status] as statusConfig">
                <div class="column-grid justify-between lendio-density-1">
                    <div class="column-grid gap-4 justify-start place-items-center">
                        <div class="mat-title-medium">Integration details</div>
                        <mat-chip class="!rounded-full !h-6 {{ statusConfig?.color }}">{{ statusConfig.label }}</mat-chip>
                    </div>
                    <button mat-flat-button
                            [disabled]="!(canManageIntegrationCredentials$ | async) || hideIntegrationCredentialsInfo()"
                            [color]="'primary'"
                            (click)="showCredentialsDialog()">
                        Update credentials
                    </button>
                </div>
                @if(!hideIntegrationCredentialsInfo() && (canManageIntegrationCredentials$ | async)) {
                    <div class="column-grid grid-cols-[140px_140px] justify-start pt-2">
                        <div>
                            <div class="mat-label-medium row-start-1">User ID</div>
                            <div class="row-start-2">{{ integration?.maskedCredentials?.username || '---' }}</div>
                        </div>
                        <div>
                            <div class="mat-label-medium row-start-1">Client ID</div>
                            <div class="row-start-2">{{ integration?.maskedCredentials?.client_id || '---' }}</div>
                        </div>
                    </div>
                }
            </mat-card>
        </ng-container>
    </div>

    @if (!(activitiesLoading$ | async) && isLoaded) {
        <mat-card appearance="outlined">
            <mat-card-header>
                <mat-card-title>Event Log</mat-card-title>
            </mat-card-header>
                <mat-card-content>
                    <mat-table [dataSource]="activitiesDataSource$ | async">
                        <mat-header-row *matHeaderRowDef="columns; sticky: true;"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: columns;"></mat-row>

                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                            <mat-cell *matCellDef="let activity"> {{ activity.action }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="dealId">
                            <mat-header-cell *matHeaderCellDef> Deal ID </mat-header-cell>
                            <mat-cell *matCellDef="let activity"> {{ activity.dealId }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="transactionId">
                            <mat-header-cell *matHeaderCellDef> Ext. Transaction. ID </mat-header-cell>
                            <mat-cell *matCellDef="let activity"> {{ activity.externalTransactionId }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                            <mat-cell *matCellDef="let activity"> {{ activity.errored ? 'Failed' : 'Success' }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="timestamp">
                            <mat-header-cell *matHeaderCellDef> Date/Time </mat-header-cell>
                            <mat-cell *matCellDef="let activity"> {{ activity.created | date: 'MM/dd/YY \'at\' h:mma' }} </mat-cell>
                        </ng-container>
                    </mat-table>

                    <div class="relative w-full">
                        <mat-divider></mat-divider>
                    </div>

                    <lendio-paginator #paginator
                                    (page)="handlePaginationEvent($event)"
                                    [length]="total$ | async"
                                    [pageSize]="pageSize$ | async"
                                    [pageSizeOptions]="pageSizeOptions$ | async"
                                    [pageIndex]="(pageIndex$ | async) - 1"
                                    [showFirstLastButtons]="true">
                    </lendio-paginator>
                </mat-card-content>
        </mat-card>
    } @else {
        <div class="text-center loading">
            <app-dot-loader-scale></app-dot-loader-scale>
        </div>
    }
</div>
