<ng-container
  *ngIf="{
    documents: documents$ | async,
    canUpload: canUpload$ | async,
  } as viewModel"
>
    <!-- Has documents -->
    @if (viewModel.documents.length) {
        <div class="flex flex-row items-start justify-between">
            <div data-cy="documents-count-header" class="mat-subtitle-2 !m-0">
                Documents
                <span class="!font-light !text-lendio-warm-gray-400">
                    <!-- toLocaleString will give us commas. e.g. 12,000 -->
                    ({{selection?.selected.length
                        ? selection.selected.length.toLocaleString()
                        : tableDataSource.filteredData.length.toLocaleString()
                    }})
                </span>
            </div>

            <!-- Top-right search and file upload button. -->
            <div class="flex items-start lendio-density-2">
                <!-- Search box -->
                <mat-form-field appearance="outline" color="accent">
                    <mat-icon matPrefix color="accent">search</mat-icon>
                    <input matInput
                        (keyup)="applyFilter($event)"
                        placeholder="Search"
                        data-cy="document-search"
                        #search>
                </mat-form-field>
                @if (viewModel.canUpload) {
                    <button mat-flat-button
                        data-cy="documents-upload-btn"
                        color="primary"
                        class="ml-2 !h-8 !min-h-0"
                        (click)="openUploadDialog()">
                        Upload
                    </button>
                }
            </div>
        </div>

        <!-- Actions buttons -->
        <div class="flex space-x-2 my-4">
            <button mat-stroked-button
                data-cy="documents-download-btn"
                class="!h-8 !min-h-0"
                color="accent"
                (click)="downloadDocs()"
                [disabled]="noneSelected() || uncleanDocsSelected()">
                Download
            </button>
            @if (showZipAll()) {
                <button mat-stroked-button
                    data-cy="documents-zip-btn"
                    class="!h-8 !min-h-0"
                    color="accent"
                    (click)="zipAll()">
                    Zip all
                </button>
            }
            <button mat-stroked-button
                data-cy="documents-delete-btn"
                class="!h-8 !min-h-0"
                color="accent"
                [disabled]="noneSelected() || aDocumentInSelectionIsNotDeletable()"
                (click)="deleteDocs($event)">
                Delete
            </button>
        </div>

        <!-- Table -->
        <table
            data-cy="documents-table"
            mat-table
            [dataSource]="tableDataSource$ | async"
            matSort
            matSortActive="id"
            matSortDirection="desc"
            #table="matTable"
        >
            <!-- Checkbox -->
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        (change)="$event ? toggleAllRows() : null"
                        [checked]="
                            (selection.hasValue() && isAllSelected()) ||
                            (selection.hasValue() && !isAllSelected())
                        "
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        color="primary"
                    >
                    </mat-checkbox>
                </th>
                <td mat-cell class="!w-8" *matCellDef="let document">
                    <mat-checkbox
                        data-cy="document-check"
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(document) : null"
                        [checked]="selection.isSelected(document)"
                        [disabled]="document?.scanStatus !== 'CLEAN'"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Doc Title -->
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                <td data-cy="document-title" mat-cell *matCellDef="let document">
                    @if (document?.scanStatus !== 'CLEAN') {
                        <div class="lendio-text-warm-gray-400">{{ document?.title }}</div>
                    } @else {
                        <button class="cursor-pointer link !text-left"
                                (click)="viewDocument(document?.id)">
                            {{ document?.title }}
                        </button>
                    }
                </td>
            </ng-container>

            <!-- Doc Type -->
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="category">Type</th>
                <td data-cy="document-type" mat-cell *matCellDef="let document">
                    {{getDocType(document?.category)}}
                </td>
            </ng-container>

            <!-- For month -->
            <ng-container matColumnDef="monthsString">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="forMonthSinceEpoch">For month</th>
                <td data-cy="document-for-month" mat-cell *matCellDef="let document">
                    @if (document?.months && document?.months.length > 0) {
                        {{formatMonthYear(document?.months[0])}}
                    }
                </td>
            </ng-container>

            <!-- Uploaded -->
            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded</th>
                <td data-cy="document-uploaded" mat-cell *matCellDef="let document" class="!py-1">
                    <div>
                        {{getDateAgo(document?.createdTimestamp ?? document?.created)}}
                    </div>
                    <div class="!font-thin text-xs text-slate-400 tracking-tight">
                        {{getDateString(document?.createdTimestamp ?? document?.created)}}
                    </div>
                </td>
            </ng-container>

            <!-- Uploaded by-->
            <ng-container matColumnDef="uploadedByName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded by</th>
                <td data-cy="document-uploaded-by" mat-cell *matCellDef="let document">
                    {{document?.uploadedByName}}
                </td>
            </ng-container>

            <!-- Scan -->
            <ng-container matColumnDef="scanStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan</th>
                <td mat-cell *matCellDef="let document">
                    @if (scanIsInconclusive(document)) {
                        <mat-spinner matTooltip="Scan in progress... This may take a few minutes."
                                    data-cy="scan-spinner"
                                    [diameter]="20">
                        </mat-spinner>
                    } @else if (document?.scanStatus === 'CLEAN') {
                        <mat-icon 
                                data-cy="scan-done"
                                class="green-check"
                                fontSet="material-symbols-outlined"
                                matTooltip="Scan successfully completed">
                            done
                        </mat-icon>
                    } @else if (document?.scanStatus === 'INFECTED') {
                        <mat-icon class="red-close"
                                fontSet="material-symbols-outlined"
                                matTooltip="Document scan failed. Try uploading a new file.">
                            close
                        </mat-icon>
                    }
                </td>
            </ng-container>

            <!-- Doc row menu button -->
            <ng-container matColumnDef="menu">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let document">
                    <button mat-icon-button
                        class="lendio-table-icon-button"
                        color="accent"
                        [matMenuTriggerFor]="docMenu"
                        data-cy="doc-row-menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>

                    <mat-menu #docMenu="matMenu"
                            [hasBackdrop]="true"
                            class="cy-menu-basic">
                        @if (document?.isEditableAndDeletable) {
                            <button mat-menu-item
                                data-cy="edit-details-btn"
                                (click)="openEditDialog(document)">
                                Edit details
                            </button>
                        }
                        <button mat-menu-item
                                data-cy="download-btn"
                                (click)="downloadDocs(document)" 
                                [disabled]="document?.scanStatus !== 'CLEAN'">
                            Download
                        </button>
                        @if (document?.isEditableAndDeletable) {
                            <button mat-menu-item
                                    data-cy="delete-btn"
                                    (click)="deleteDocs($event, document)">
                                Delete
                            </button>
                        }
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <lendio-paginator [pageSizeOptions]="[25, 50, 100]"></lendio-paginator>
    }

    <!-- No documents -->
    @if (viewModel.documents.length == 0) {
        <div data-cy="documents-empty" class="flex flex-row items-start justify-between">
            <!-- toLocaleString will give us commas. e.g. 12,000 -->
            <div data-cy="documents-count-header" class="mat-subtitle-2">Documents <span class="!text-lendio-warm-gray-300">({{
                selection?.selected.length
                    ? selection.selected.length.toLocaleString()
                    : tableDataSource.filteredData.length.toLocaleString()
            }})</span></div>

            <!-- file upload button. -->
            <div class="flex items-start lendio-density-2">
                @if (viewModel.canUpload) {
                    <button mat-flat-button
                            data-cy="documents-upload-btn"
                            color="primary"
                            class="ml-2 !h-8 !min-h-0"
                            (click)="openUploadDialog()">
                        Upload
                    </button>
                }
            </div>
        </div>
        <div class="my-4 m-auto">
          <app-empty-state [type]="emptyType" [label]="emptyLabelContent">
            <div class="text-center font-normal text-gray-900 mt-2" messageContent>
              @if (viewModel.canUpload) {
                Click <a class="text-blue-500 hover:text-blue-800 cursor-pointer" (click)="openUploadDialog()">Upload</a> {{ uploadText }}
              }
            </div>
          </app-empty-state>
        </div>
    }
</ng-container>
