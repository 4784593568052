<div data-cy="file-upload-dialog">
    <div class="flex justify-between content-center">
        <h2 mat-dialog-title class="!pb-4" data-cy="decline-modal-header">{{ data?.title ?? 'Upload '}}</h2>
        <button (click)="close($event)" class="pr-6">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="!p-0">
        <file-upload
            [allowedFileTypes]="data?.allowedFileTypes"
            [fileSizeLimit]="data?.fileSizeLimit"
            [fileCount]="data?.fileCount"
            [label]="data?.label"
            [inline]="data?.inline"
            [fileSelectText]="data?.fileSelectText"
            [buttonText]="data?.buttonText"
            (fileInput)="handleFileInput($event)"
        >
        </file-upload>
    </mat-dialog-content>
    <mat-divider class="!border-t-2"></mat-divider>
    <mat-dialog-actions align="end">
        <button 
                data-cy="upload-close-button" 
                mat-stroked-button color="accent" 
                class="!mt-4" 
                (click)="close($event)">
            {{ data?.closeLabel ?? 'Close' }}
        </button>
    </mat-dialog-actions>
</div>
