import {Injectable} from '@angular/core';
import { Store } from '@ngxs/store';
import { SettingsState } from '../store/settings/settings.state';
import { UpdatePendoInitialized } from 'src/app/store/settings/settings.actions';
import cookies from '../libs/cookieManager';
import { UUID } from 'angular2-uuid';
import { environment } from 'src/environments/environment';
import { AuthUser } from '../store/auth/auth-user';

const TEST_USER_EMAILS: Array<string | undefined> = [
    'lpgal@lendio.com',
    'test_referral_partner@lendio.com',
    'pipelineguy@lendio.com'
]

const LENDIO_INSTITUTION_ID = 44566

@Injectable({
    providedIn: 'root'
})
export class PendoService {

  constructor(private store: Store){}

  identify (portalUser: AuthUser) {
    let timezone

    // Bypass cypress tests from creating pendo visitors
    if (TEST_USER_EMAILS.includes(portalUser.email)) return
    // Disable staging and dev environments by default
    if (!window?.pendo || !environment.enablePendo) return

    const accountId = portalUser.role === 'lender' ? portalUser.institution?.id : portalUser.affiliate?.id
    // Bypass internal Lendio users from creating pendo visitors
    if (accountId === LENDIO_INSTITUTION_ID) return

    if (this.store) {
        const pendoInitialized = this.store.selectSnapshot(SettingsState.pendoInitialized);

        if (pendoInitialized) {
            return
        }
    } else {
        return
    }

    this.initialize(portalUser.id)

    try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (e) {
        timezone = null
    }

    const properties = {
      visitor: {
        // Removing the anonymousID and anonymous user tracking to limit pendo MAU #s
        id: this.prepareId(portalUser.id),
        email: portalUser.email,
        name: portalUser.fullName,
        role: portalUser.role,
        timezone,
      },
      account: {
        id: this.prepareId(accountId),
        type: portalUser.institution ? 'lender' : 'affiliate',
        medium: portalUser.affiliate?.medium,
        name: portalUser.institution?.name ?? portalUser.affiliate?.name
      },
    }

    window.pendo.identify(properties)
  }

  initialize (userId?: number) {
    let anonymousId = cookies.get('anonymousId')

    if (!anonymousId) {
        const randomId = UUID.UUID()
        anonymousId = cookies.set('anonymousId', randomId)
    }

    const visitorId = userId ?? anonymousId

    if (!window.pendo || !environment.enablePendo) return

    window.pendo.initialize({
      visitor: { id: this.prepareId(visitorId) },
    })

    this.store.dispatch(new UpdatePendoInitialized(true))
  }

  // trackEvent(event, properties, context) {
  //   if (!window.pendo) return
  //   const eventProperties = this._prepareEventProperties(properties, context)
  //   const pendoProperties = {}

  //   Object.keys(eventProperties).forEach((k) => {
  //     pendoProperties[this._prepareKeyName(k)] = eventProperties[k]
  //   })

  //   window.pendo.track(event, pendoProperties)
  // },

  private prepareId (id: any) {
    const environments: Record<string, string> = {
      'lender.lendio.com': 'production'
    }
    const env = window.location.host

    if (environments[env] === 'production') {
      return id
    }

    return `dev-${id}`
  }

  // _prepareEventProperties(properties, context) {
  //   const {
  //     name: utm_campaign,
  //     source: utm_source,
  //     medium: utm_medium,
  //     term: utm_term,
  //     content: utm_content,
  //   } = context.campaign

  //   return {
  //     ...properties,
  //     ...context.page,
  //     screen_height: context.screen.height,
  //     screen_width: context.screen.width,
  //     timezone: context.timezone,
  //     utm_campaign,
  //     utm_source,
  //     utm_medium,
  //     utm_term,
  //     utm_content,
  //   }
  // }

  // _prepareKeyName(key) {
  //   // Accepts lowercase a-z, numbers & single underscores.
  //   return key
  //     .replace(/[a-z][A-Z]/g, (letters) => `${letters[0]}_${letters[1]}`)
  //     .toLowerCase()
  //     .replace(/ - |-|__| /g, '_')
  // }
}
