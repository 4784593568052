import { inject, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdministrationComponent } from './administration/administration.component';
import { authGuard } from "@app/app/guards/auth.guard";
import { NotFoundComponent } from "@app/app/components/errors/not-found/not-found.component";
import { saasFeatureGuard } from '@app/app/guards/saas-features.guard';
import { TeamMembersTableComponent } from '@app/app/components/administration/team/team-members-table/team-members-table.component';
import { TeamMembersModule } from '@app/app/components/administration/team/team-members.module';
import { RedirectionGuard } from '@app/app/guards/redirection.guard';
import { MakeCsvLiteComponent } from '@app/app/components/make-csv-lite/make-csv-lite.component';
import { IntegrationsPageComponent } from '@app/app/components/integrations/integrations-page.component';
import {
  IntegrationsActivityTableComponent
} from '@app/app/components/integrations/integrations-activity-table/integrations-activity-table.component';
import { GetIntegrations } from '@app/app/store/integrations/integrations.actions';
import { Store } from '@ngxs/store';
import { BreadcrumbService } from '@app/app/services/breadcrumb.service';
import { lendioOnlyGuard } from '@app/app/guards/lendio-only.guard';

const routes: Routes = [
  {
    path: '',
    component: AdministrationComponent,
    children: [
      {
        path: 'import',
        loadChildren: () => import('@app/app/components/lender-imports/imports.module').then(m => m.ImportsModule),
        canActivate: [authGuard, saasFeatureGuard],
        data: {
          saasFeature: 'administrationImport',
          permission: 'lpxAdministrationImport.view'
        }
      },
      {
        path: 'api',
        loadChildren: () => import('@app/app/components/api-page/api-page.module').then(m => m.ApiPageModule),
        canActivate: [authGuard, saasFeatureGuard],
        data: {
          saasFeature: 'administrationGetApiCreds',
          permission: 'lpxAdministrationGetApiCreds.view'
        }
      },
      {
        path: 'intelligent-lending',
        loadChildren: () => import('@app/app/components/embedded-app/embedded-app.module').then(m => m.EmbeddedAppModule),
        canActivate: [authGuard, saasFeatureGuard],
        data: {
          type: 'intelligent_lending',
          saasFeature: 'administrationIntelligentConfiguration',
          permission: 'lpxAdministrationIntelligentConfiguration.view',
          affiliateMediumRequired: 'Intelligent Lending'
        },
      },
      {
        path: 'embedded-app',
        loadChildren: () => import('@app/app/components/embedded-app/embedded-app.module').then(m => m.EmbeddedAppModule),
        canActivate: [authGuard, saasFeatureGuard],
        data: {
          type: 'embedded',
          saasFeature: 'administrationEmbeddedConfiguration',
          permission: 'lpxAdministrationEmbeddedConfiguration.view',
          affiliateMediumRequired: 'Embedded'
        }
      },
      {
        path: 'team',
        loadChildren: () => TeamMembersModule,
        component: TeamMembersTableComponent,
        title: 'Administration',
        canActivate: [authGuard, saasFeatureGuard],
        data: {
          saasFeature: 'administrationManageTeam',
          permission: 'lpxAdministrationManageTeam.view'
        }
      },
      {
        path: 'makeCsv',
        component: MakeCsvLiteComponent,
        canActivate: [authGuard, lendioOnlyGuard],
        title: 'MakeCsv',
      },
      {
        path: 'integrations',
        title: 'Integrations',
        loadChildren: () => import('@app/app/components/integrations/integrations.module').then(m => m.IntegrationsModule),
        component: IntegrationsPageComponent,
        canActivate: [authGuard],
        resolve: [() => {
          const store = inject(Store);
          store.dispatch( new GetIntegrations() );
        }],
      },
      {
        path: 'integrations/:id',
        title: 'Integration Activity',
        loadChildren: () => import('@app/app/components/integrations/integrations.module').then(m => m.IntegrationsModule),
        component: IntegrationsActivityTableComponent,
        canActivate: [authGuard],
        resolve: [() => {
          const store = inject(Store);
          store.dispatch( new GetIntegrations() );
          inject(BreadcrumbService).setBreadcrumbs([
            { label: 'Integrations', path: '/administration/integrations' }
          ]);
        }],
      },
      {
        path: '',
        canActivate: [RedirectionGuard],
        pathMatch: 'full',
        component: NotFoundComponent
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
  providers: [RedirectionGuard]
})

export class AdministrationRoutingModule {
}
